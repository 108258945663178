import React from "react"
import classes from "./NavigationItem.css"
import { NavLink } from "react-router-dom";
import Arrow from "./Arrow.svg"

const navigationItem = (props) => {

  return (
    <div className={classes.navItemContainer}>
      <p className={props.orderButton ? classes.orderButton : classes.labelContainer}>
        <NavLink className={props.orderButton ? classes.orderLabel : classes.label} style={props.orderButton ? {color: "white"} : {}} activeClassName={classes.selected} to={props.page}>{props.title}
          {!props.orderButton ? <img className={classes.arrow} src={Arrow} alt=""/> : <></>}
        </NavLink>
      </p>
    </div>
  );
}

export default navigationItem;
