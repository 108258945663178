import React from "react";
import ReactGA from "react-ga4";
import Visual from "../Visuals/Visual 9.jpeg";
import commonClasses from "../ArticleStyle.css"
import classes from "./iotswc.css"
import FatArrow from "../../../containers/UI/FatArrow.svg"

const News = (props) => {

  return (
    <>
      <div className={commonClasses.container}>

        <div className={commonClasses.innerContainer}>
          <h3 className={commonClasses.dateLabel}>Barcelona, May 23rd 2024</h3>
          <h1>Allorado shines at IoT Solutions World Congress</h1>
          
          <div className={classes.visualContainer}>
            <img src={Visual} className={commonClasses.visual} alt="Indumation the award ceremony"/>
          </div>

          <p className={commonClasses.contentParagraph}>Allorado participated in the IoT Solutions World Congress in Barcelona as a member of the KNX Startup Incubator. This event, provided Allorado with an excellent platform to showcase its building automation knowledge to the IoT industry.</p>
          
          <div className={commonClasses.callToAction}>
            <h3>Interested in Allorado solutions?</h3>
            <div className={commonClasses.orderRow}>
              <button className={commonClasses.orderButton} onClick={() => {
                  ReactGA.event({ category: "Button", action: "News item to contact" })
                  window.location = "mailto:sales@allorado.io?subject=Interested in Allorado"
                }}>Get in touch today</button>
              <img src={FatArrow} className={commonClasses.arrow} alt="arrow"/>
            </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default News;
