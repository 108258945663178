import React from "react";
import classes from "../Style.css"
import DocPage from "../DocPage/DocPage"
import { NavLink } from "react-router-dom";

const DocumentationPage = (props) => {

  return (
    <>
      <DocPage title={"Updating the decoding library"} link={"https://gateway.allorado.io/docs/7.%20Updating%20decoding%20library.pdf"}>
        <div className={classes.docContainer}>
          <p>The device decoding library is frequently updated, whenever new decodings are implemented by the team. New releases are published as a .deb file on the <NavLink className={classes.docLink} to={"/software"}>software page</NavLink>.</p>
          
          <h3>Updating the library</h3>
          <ol>
            <li>Download the latest version from the <NavLink className={classes.docLink} to={"/software"}>software page</NavLink></li>
            <li>Log into the gateway's web interface</li>
            <li>Head over to “Software Update”</li>
            <li>Install the recently downloaded .deb file by clicking the <b>upload</b> button</li>
            <li>When installation is complete, the “Installed version” field will update automatically</li>
            <li>There's no need to restart the gateway, changes will take effect immediately</li>
            <li>The new device models will be visible in the list when adding a new LoRa device </li>
          </ol>
        </div>
      </DocPage>
    </>
  );
}

export default DocumentationPage;
