import axios from "axios";
import { presentAlert } from "./containers/UI/Alert/Alert";

const instance = axios.create({
    baseURL: "http://" + window.location.hostname + ":8765/api/v1.0"
});


// Add a 504 response interceptor (happens with database faults)
instance.interceptors.response.use((response) => {
    return response;
}, function (error) {

    // Handle 503 when database not running
    if (error.response) {
      if (error.response.status === 503) {
        if (error.response.data) {
          if (error.response.data.message) {
            presentAlert({
              title: "Backend error",
              message: error.response.data.message,
              error: true
            })
          }
        }
      }
    } else if (!error.status) {
      presentAlert({
        title: "Backend error",
        message: "The backend is currently unreachable. Please wait for it to start up.",
        error: true
      })
    } else {
      presentAlert({
        title: "Backend error",
        message: "An unknown backend error occured.",
        error: true
      })
    }

    return Promise.reject(error);
});


export default instance;
