import React from "react";
import classes from "../Style.css"
import DocPage from "../DocPage/DocPage"
import { NavLink } from "react-router-dom"
import CollapseRow from "../../../../containers/UI/CollapseRow/CollapseRow";

import Figure1 from "../Figures/12-1.png"
import Figure2 from "../Figures/12-2.png"
import Figure3 from "../Figures/12-3.png"
import Figure4 from "../Figures/12-4.png"
import Figure5 from "../Figures/12-5.png"
import Figure6 from "../Figures/12-6.png"
import Figure7 from "../Figures/12-7.png"
import Figure8 from "../Figures/12-8.png"
import Figure9 from "../Figures/12-9.png"
import Figure10 from "../Figures/12-10.png"
import Figure11 from "../Figures/12-11.png"
import Figure12 from "../Figures/12-12.png"
import Figure13 from "../Figures/12-13.png"
import Figure14 from "../Figures/12-14.png"



const DocumentationPage = (props) => {

  return (
    <>
      <DocPage title={"NodeRED example flows"} link={"https://gateway.allorado.io/docs/12.%20Example%20flows.pdf"}>
        <div className={classes.docContainer}>
          <p>Using NodeRED can be overwhelming at first, but has a gentle learning curve. To assist users in realizing their projects with the Allorado Gateway, we provide a wide range of example flows.</p>

          <h3>Experimenting with LoRa</h3>

          <CollapseRow title={"1. Print out LoRa messages"} difficulty={"Beginner"} link={"https://gateway.allorado.io/flows/1.%20Print%20out%20LoRa%20messages.json"} firstRow>
            <p>The simplest flow of all, consisting of only two nodes. This flow prints out all messages from LoRa devices to the NodeRED console. Familiarize yourself with importing an example flow.</p>
            <img style={{ maxHeight: "55px" }} className={classes.flowImage} src={Figure1} alt="NodeRED flow demonstrating printing of LoRa messages"/>
            <p>To open a node’s <b>configuration panel</b>, double-click it. When double-clicking the orange node, you see a dropdown to select a LoRa device and a dropdown for a LoRa controller.</p>
            <p>The LoRa controller is only there for the internal working of the nodes. A single LoRa controller should be reused across all NodeRED nodes.</p>
            <p>Feel free to play around with the “LoRa device” setting, changing it from “All devices” to only a specific device. When a message is sent out by a LoRa device (which you can see on the Live Traffic view), a message will also be sent out from this node and printed by the green node.</p>
            <p>Head over to the debug tab (the bug icon in the right sidebar) and inspect the outgoing message to understand what information is provided by the LoRa Uplink node.</p>
          </CollapseRow>
          <CollapseRow title={"2. Send a LoRa downlink"} difficulty={"Beginner"} link={"https://gateway.allorado.io/flows/2.%20Send%20a%20LoRa%20downlink.json"}>
            <p>This flow sends a downlink message to a LoRa device when the user clicks the “Send” node.</p>
            <img style={{ maxHeight: "47px" }} className={classes.flowImage} src={Figure2} alt="NodeRED flow demonstrating injecting LoRa downlinks"/>
            <p>The downlink node can be configured in two places. For ease of use and quick testing, the configuration panel of the node allows you to set the downlink parameters.</p>
            <p>In more complex flows, you’ll maybe want to change the parameters based on certain conditions, which can be done in the function node. When parameters are defined both in the injecting message (i.e. the function node) and on the configuration panel, the injected message always takes priority.</p>
            <p>Play around with this flow by selecting a LoRa device in the dropdown of the configuration panel. In the function node, set an appropriate payload for your device.</p>
            <p>Class C devices are always online and reachable for downlinks, while class A devices are only online periodically. Downlinks to class C are sent immediately, downlinks to class A are queued until the device is ready to receive a downlink. It is therefore important to <b>correctly set the class</b> of the device, as this determines how the downlink is transmitted.</p>
          </CollapseRow>
          <CollapseRow title={"3. Display LoRa value on a dashboard"} difficulty={"Beginner"} link={"https://gateway.allorado.io/flows/3.%20Display%20LoRa%20value%20on%20a%20dashboard.json"}>
            <p>This flow visualizes a temperature sent out by a LoRa device in a NodeRED dashboard.</p>
            <img style={{ maxHeight: "48px" }} className={classes.flowImage} src={Figure3} alt="NodeRED flow demonstrating LoRa to dashboard"/>
            <p>The NodeRED dashboard nodes allow for rapid visualization of data sent out by sensors. Basic visualization tools like graphs, gauges and text are available. Control elements like switches, sliders, buttons and text inputs are also available for more complex use cases.</p>
            <p>Each dashboard node is part of a group (a section on the dashboard page), each group is part of a tab (the dashboard can switch between multiple tabs).</p>
            <p>The dashboard can be accessed in multiple ways. One can directly open the dashboard by surfing to the NodeRED URL and adding “/ui”. If your gateway has IP address 192.168.1.5, then the direct URL becomes <div className={classes.inlineCode}>192.168.1.5:1880/ui</div>. The page can also be accessed by clicking the down arrow on the top right of NodeRED, selecting the “Dashboard” tab and then clicking the link button on the right.</p>
          </CollapseRow>
          <CollapseRow title={"4. Send a push notification on LoRa message"} difficulty={"Beginner"} link={"https://gateway.allorado.io/flows/4.%20Send%20a%20push%20notification%20on%20LoRa%20message.json"} lastRow>
            <p>This flow sends out a push notification to your Android device by leveraging a third-party service.</p>
            <img style={{ maxHeight: "48px" }} className={classes.flowImage} src={Figure4} alt="NodeRED flow demonstrating push notifications based on LoRa messages"/>
            <p>To test out this flow, you will first need to install the third-party node. In the upper right corner of NodeRED, open the menu. Click “Manage Palette” and head over to the “Install” tab. Enter “pushbullet” and install the corresponding node.</p>
            <p>Once installed, the node will be added to the node palette on the left and you can correctly import the example flow. To get this flow to work, you need an API key from PushBullet, which can be obtained on their website and entered in the node controller configuration.</p>
          </CollapseRow>

          <h3>Combining LoRa with other protocols</h3>
          <CollapseRow title={"5. LoRa to MQTT"} difficulty={"Intermediate"} link={"https://gateway.allorado.io/flows/5.%20LoRa%20to%20MQTT.json"} firstRow>
            <p>This flow sends out an MQTT message containing the decoded values sent out by a LoRa device.</p>
            <img style={{ maxHeight: "62px" }} className={classes.flowImage} src={Figure5} alt="NodeRED flow demonstrating LoRa to MQTT"/>
            <p>In the MQTT node, edit the broker configuration to the broker you plan to use. In the LoRa node on the left, select all devices or a specific LoRa device provisioned on the web interface.</p>
            <p>This example flow provides two methods of sending out messages. If a LoRa telegram contains multiple values, e.g. a room temperature, humidity and CO2 value, two philosophies are possible. Method 1 sends out one MQTT message with the three values all at once. Method 2 sends out three separate MQTT messages, one for each value. Feel free to comment out either of the methods in the function node.</p>
          </CollapseRow>
          <CollapseRow title={"6. LoRa to BACnet "} difficulty={"Intermediate"} link={"https://gateway.allorado.io/flows/6.%20LoRa%20to%20BACnet.json"}>
            <p>This flow updates a BACnet object with a value sent out by a LoRa device.</p>
            <img style={{ maxHeight: "62px" }} className={classes.flowImage} src={Figure6} alt="NodeRED flow demonstrating LoRa to BACnet"/>
            <p>For a better understanding of the BACnet nodes, please refer to our <NavLink className={classes.docLink} to={"/docs/using-bacnet"}>“Using BACnet”</NavLink> guide.</p>
            <p>Two different LoRa devices are exposed to BACnet. In the BACnet node, three objects are defined: a relay state, a temperature and a humidity value. The function node keeps track of which LoRa device values are mapped to which BACnet instance ID. Change the DevEUI and field names for your specific sensor and get cracking.</p>
            <p>The result can be viewed with a BACnet explorer like Yabe.</p>

          </CollapseRow>
          <CollapseRow title={"7. LoRa to KNX "} difficulty={"Intermediate"} link={"https://gateway.allorado.io/flows/7.%20LoRa%20to%20KNX.json"}>
            <p>This flow sends out KNX telegrams to a group address with a value sent out by a LoRa device.</p>
            <img style={{ maxmaxHeight: "52px" }} className={classes.flowImage} src={Figure7} alt="NodeRED flow demonstrating LoRa to KNX"/>
            <p>For a better understanding of the KNX nodes, please refer to our <NavLink className={classes.docLink} to={"/docs/knx-nodes"}>“KNX nodes</NavLink> guide.</p>
            <p>Two different LoRa devices are exposed to KNX. The function node keeps track of which LoRa device values are mapped to which KNX group address and the corresponding DPT of that address. Change the KNX IP interface settings in the KNX node and change the DevEUI and field names for your specific sensor and get cracking.</p>
          </CollapseRow>
          <CollapseRow title={"8. MQTT to LoRa downlink "} difficulty={"Intermediate"} link={"https://gateway.allorado.io/flows/8.%20MQTT%20to%20LoRa%20downlink.json"}>
            <p>This flow accepts incoming MQTT messages and sends out LoRa downlink messages.</p>
            <img style={{ maxHeight: "62px" }} className={classes.flowImage} src={Figure8} alt="NodeRED flow demonstrating MQTT to LoRa downlink"/>
            <p>This example flow allows you to send downlinks to any LoRa device provisioned on the gateway by sending an MQTT message to the topic <i>lora/downlink</i>. An example using the mosquitto shell utilities:</p>
            <div className={classes.code}>mosquitto_pub -t "lora/downlink" -m '{"{"}message":"1150000602", "deveui": "70B3D5E75E004693", "port": 125{"}"}'</div> 
            <p>The message body should be a JSON object containing the DevEUI, port and message payload.</p>
          </CollapseRow>
          <CollapseRow title={"9. BACnet to LoRa downlink "} difficulty={"Intermediate"} link={"https://gateway.allorado.io/flows/9.%20BACnet%20to%20LoRa%20downlink.json"}>
            <p>This flow listens for updates of BACnet object values and sends out LoRa downlink messages.</p>
            <img style={{ maxHeight: "56px" }} className={classes.flowImage} src={Figure9}  alt="NodeRED flow demonstrating BACnet to LoRa downlink"/>
            <p>For a better understanding of the BACnet nodes, please refer to our <NavLink className={classes.docLink} to={"/docs/using-bacnet"}>“Using BACnet”</NavLink> guide.</p>
            <p>This example flow defines a single BACnet object in the leftmost node. To allow two-way communication, the type “binary value” is selected instead of “binary input”, which is read-only. The function node composes a valid downlink message based on the BACnet value </p>
            <p>This flow implements control of a smart plug from BACnet, but can easily be reused for other purposes. Testing can easily be done by using a BACnet explorer like Yabe. Make sure to turn off the “Out-of-Service” flag before writing a new value to avoid access denied errors.</p>
          </CollapseRow>
          <CollapseRow title={"10. KNX to LoRa downlink "} difficulty={"Intermediate"} link={"https://gateway.allorado.io/flows/10.%20KNX%20to%20LoRa%20downlink.json"} lastRow>
            <p>This flow listens for writes to a KNX group address and sends out LoRa downlink messages.</p>
            <img style={{ maxmaxHeight: "58px" }} className={classes.flowImage} src={Figure10}  alt="NodeRED flow demonstrating KNX to LoRa downlink"/>
            <p>For a better understanding of the KNX nodes, please refer to our <NavLink className={classes.docLink} to={"/docs/knx-nodes"}>“KNX nodes”</NavLink> guide.</p>
            <p>This example listens for writes on the group address 1/1/0. The first function node looks up the DPT for each group address, so the KNX Decode node can properly decode the binary value into something human-readable. The second function node composes a valid downlink message based on the received KNX value. Correctly set the address of the KNX IP Interface and get cracking.</p>
          </CollapseRow>


          <h3>Advanced couplings</h3>
          <CollapseRow title={"11. Coupling LoRa and BACnet"} difficulty={"Advanced"} 
link={"https://gateway.allorado.io/flows/11.%20Coupling%20LoRa%20and%20BACnet.json"} firstRow>
            <p>This flow creates a two-way coupling between a BACnet object and a LoRa device.</p>
            <img className={classes.flowImage} src={Figure11} alt="NodeRED flow demonstrating coupling BACnet and LoRa"/>
            <p>For a better understanding of the BACnet nodes, please refer to our <NavLink className={classes.docLink} to={"/docs/using-bacnet"}>“Using BACnet”</NavLink> guide.</p>
            <p>Three BACnet objects are defined in the green node. When a LoRa message comes in, the function node checks whether this message contains values which are mapped to BACnet. If it does, an update is injected into the BACnet node updating the BACnet objects.</p>
            <p>One of the objects is bidirectionally writable: the relay state of a smart plug. When another BACnet device updates this object, a message is sent out and the function node composes a proper LoRa downlink message to change the state of the smart plug relay. Testing can easily be done by using a BACnet explorer like Yabe. Make sure to turn off the “Out-of-Service” flag before writing a new value to avoid access denied errors.</p>
            <p>Exposing LoRa values to BACnet is entirely possible with the web interface automatic mapper, too. Sending downlinks to LoRa devices based on BACnet value changes is only possible in NodeRED currently. </p>
          </CollapseRow>
          <CollapseRow title={"12. Coupling BACnet and KNX "} difficulty={"Advanced"} link={"https://gateway.allorado.io/flows/12.%20Coupling%20BACnet%20and%20KNX.json"}>
          <p>This flow creates a two-way coupling between a BACnet object and a KNX group address.</p>
            <img className={classes.flowImage} src={Figure12} alt="NodeRED flow demonstrating coupling BACnet and KNX"/>
            <p>For a better understanding of the KNX nodes, please refer to our <NavLink className={classes.docLink} to={"/docs/knx-nodes"}>“KNX nodes”</NavLink> guide. <br/> For a better understanding of the BACnet nodes, please refer to our <NavLink className={classes.docLink} to={"/docs/using-bacnet"}>“Using BACnet”</NavLink> guide.</p>
            <p>This example flow maps three KNX group address to three BACnet objects. When a KNX telegram comes in, the correct DPT is looked up and the value is decoded. If the telegram is relevant, a BACnet object update will be injected into the green node. When a BACnet object value changes, the function node looks up the corresponding group address and DPT and sends out a message. The telegram is encoded and sent onto the KNX bus.</p>
            <p>This flow could be extended to more group addresses / objects by creating a globally stored variable with mappings of group address, DPT and offset IDs.</p>
          </CollapseRow>
          <CollapseRow title={"13. Coupling BACnet and MQTT "} difficulty={"Advanced"} link={"https://gateway.allorado.io/flows/13.%20Coupling%20BACnet%20and%20MQTT.json"}>
            <p>This flow creates a two-way coupling between BACnet objects and an MQTT interface.</p>
            <img className={classes.flowImage} src={Figure13} alt="NodeRED flow demonstrating coupling BACnet and MQTT"/>
            <p>For a better understanding of the BACnet nodes, please refer to our <NavLink className={classes.docLink} to={"/docs/using-bacnet"}>“Using BACnet”</NavLink> guide.</p>
            <p>This example flow exposes three BACnet objects via an MQTT interface. Object values can be updated by sending out an MQTT message on the topic <div className={classes.inlineCode}>bacnet/setValue</div>. An example using shell mosquitto utilities:</p>
            <div className={classes.code}>mosquitto_pub -t "bacnet/setValue" -m '{"{"}"offsetID": 2, "value": 45{"}"}'</div>
            <p>When the value of an object changes (due to interaction with other BACnet devices), a message is sent out over the MQTT interface on the topic <div className={classes.inlineCode}>bacnet/updatedValue</div>. Testing can easily be done by using a BACnet explorer like Yabe. Make sure to turn off the “Out-of-Service” flag before writing a new value to avoid access denied errors.</p>
          </CollapseRow>
          <CollapseRow title={"14. Coupling KNX and MQTT "} difficulty={"Advanced"} link={"https://gateway.allorado.io/flows/14.%20Coupling%20KNX%20and%20MQTT.json"} lastRow>
            <p>This flow creates a two-way coupling between KNX group addresses and an MQTT interface.</p>
            <img className={classes.flowImage} src={Figure14} alt="NodeRED flow demonstrating coupling KNX and MQTT"/>
            <p>For a better understanding of the KNX nodes, please refer to our <NavLink className={classes.docLink} to={"/docs/knx-nodes"}>“KNX nodes”</NavLink> guide.</p>
            <p>This example flow creates an MQTT interface to write to KNX group addresses and receive updates when a telegram is sent on a group address. An example using shell mosquitto utilities:</p>
            <div className={classes.code}>mosquitto_pub -t "knx/setValue" -m '{"{"}"address": "1/2/0", "value": 15{"}"}'</div>
            <p>When another KNX devices writes to a group, a message is sent out over the MQTT interface on the topic <div className={classes.inlineCode}>knx/updatedValue</div>.</p>
          </CollapseRow>
          
          <div className={classes.standaloneTip}>
            <div><b>Warning</b>:</div>
            <div>Some node types should only have one instance. When importing these example flows, it is easy to overlook this and have multiple controller instances. This will lead to connection problems, weird issues and possibly crashes. Please carefully check each controller dropdown, delete duplicate instances and make sure all nodes of a protocol have the same controller selected. These include the <b>LoRa controller</b>, <b>BACnet controller</b> and <b>KNX stack</b> nodes.</div>
          </div>
        </div>
      </DocPage>
    </>
  );
}

export default DocumentationPage;
