import React from "react";
import CollapseRow from "../../containers/UI/CollapseRow/CollapseRow";
import { NavLink } from "react-router-dom";
import NetworkingImage from "./Images/Networking.png";
import classes from "./Resources.css"

const Docs = (props) => {

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>

        <div className={classes.faqContainer}>
          <h1>Frequently asked questions</h1>

          <CollapseRow title={"1 - Default IP setting"} firstRow>
            <p>
            The Allorado Gateway has two Ethernet ports:
            </p>
            <ul>
              <li>The left port has a static IP address for easy configuration.</li>
              <li>The right port is configured as DHCP, to connect to your network.</li>
            </ul>
            <img src={NetworkingImage} className={classes.networkingImage} alt="Networking explanation"/>
            <h4 style={{ marginTop: "60px" }}>Left port (P2)</h4>
            <ul>
              <li>Static IP address 192.168.10.100</li>
              <li>Subnet mask 255.255.255.0</li>
            </ul>
            <ol>
              <li>Plug your PC directly into this port</li>
              <li>Assign a different IP to your PC, e.g. 192.168.10.50</li>
              <li>Surf to http://192.168.10.100 to access the Allorado Gateway</li>
            </ol>
            <h4>Right port (P1)</h4>
              <ol>
                <li>Configured as DHCP</li>
                <li>Plug this port into your local network, it gets an IP from your router</li>
                <li>Find the gateway's IP address by consulting your router</li>
                <li>Surf to the address to access the Allorado Gateway</li>
              </ol>
          </CollapseRow>

          <CollapseRow title={"2 - How to log in"}>
            The magic of the Allorado Gateway happens on its web interface, a page you can access through your browser.
            Once you have found the gateway's IP address, you can simply surf to it by typing http://[your IP address].
            <br />
            Youl'll be presented with the login page. The default login credentials:
            <ul>
              <li><strong>Username</strong>: admin</li>
              <li><strong>Password</strong>: admin</li>
            </ul>

            It is advised to change these immediately! <br />
            Navigate to System Configuration to change the admin password.<br />
            Accessing NodeRED happens using the same credentials you created for the web interface.
          </CollapseRow>

          <CollapseRow title={"3 - Meaning of the status LEDs"}>
            <h4>STAT light</h4>
            <ul>
              <li>Blinks green when the gateway has loaded the operating system</li>
              <li>Solid red when the gateway has failed to load an operating system</li>
            </ul>

            <h4>USER light</h4>
            <ul>
              <li>Green: gateway has fully booted correctly</li>
              <li>Orange: gateway tries to recover from a system error</li>
              <li>Red: gateway will reboot soon to recover</li>
            </ul>
          </CollapseRow>

          <CollapseRow title={"4 - Installing a license"}>
            <p>
            A lifetime license comes preinstalled on gateways assembled by the Allorado team, in which case this tutorial doesn't apply.<br /><br />
            When sourcing hardware yourself from Siemens and an approved LoRa radio manufacturer, Allorado can generate a license based on the serial number of your gateway. Detailed instructions on how to install the Allorado software can be obtained after inquiry, contact<button className={classes.mailButton} onClick={() => window.location = "mailto:support@allorado.io?subject=Installing Allorado software"}>support@allorado.io</button><br /><br />
            </p>
            <ul>
              <li>Once you've obtained the .pem license file, log into the web interface and navigate to System Configuration.</li>
              <li>From there, click Install license and upload the .pem file.</li>
              <li>After installing, the gateway's capabilities will be activated immediately.</li>
            </ul>
          </CollapseRow>

          <CollapseRow title={"5 - Requesting a new decoding"}>
            Decodings are implemented by the Allorado team and are released to the community.
            The library already consists of hundreds of existing devices from many manufacturers.
            New decoding are added free of charge, since the whole community benefits from them.
            <br /><br />
            To request a new device decoding in the library:

            <ul>
              <li>Obtain the datasheet from the manufacturer. This document states the exact meaning of every byte in the payload.</li>
              <li>Optionally, gather some example payloads, so we can test the decoding.</li>
              <li>Send your request along with the above to<button className={classes.mailButton} onClick={() => window.location = "mailto:support@allorado.io?subject=Requesting a new decoding"}>support@allorado.io</button></li>
            </ul>
            Once implemented, follow the steps below (FAQ no. 7) to install the latest device library.
          </CollapseRow>

          <CollapseRow title={"6 - Testing a new LoRa device"}>
            When setting up a new LoRa device, it can be difficult to test whether it actually functions. <br />
            Allorado has a <strong>Live Traffic view</strong> to assist you. This real-time view will show you when a device joins the network (i.e. comes online) and when it transmits actual data.
            <br /><br />
            The proper testing procedure:
            <ol>
              <li>In LoRa Devices, add a new device by inserting the keys and naming it.</li>
              <li>Head over to the Live Traffic view.</li>
              <li>Activate the device by inserting the battery or pressing a reset button. Make sure it isn't joined to any other nearby network (by removing the keys from other gateways you may have).</li>
              <li>You'll see the device join on the live traffic view. Click the balloon for more information.</li>
              <li>Depending on its configuration, the device might transmit data right away, or it might wait until later. If the device is in the library and you've selected its model identifier when adding it, decoded values will also appear after clicking the blue balloon.</li>
            </ol>
          </CollapseRow>

          <CollapseRow title={"7 - Updating the device library"} lastRow>
            The device library is frequently updated, whenever new decodings are implemented by the team. New releases are published as a .deb file on this page, along with a changelog.
            <ol>
              <li>Download the latest version from the Software page on this website</li>
              <li>Log into the gateway's web interface</li>
              <li>Head over to Software Update</li>
              <li>Install the recently downloaded .deb file by clicking the upload button</li>
              <li>When creating a new device in LoRa Devices, the newly added models will be listed</li>
              <li>There's no need to restart the gateway, changes will take effect immediately</li>
            </ol>
          </CollapseRow>
        </div>

        <div className={classes.videoContainer}>
          <h1>Video tutorials</h1>
          <CollapseRow title={"Setting up a new Allorado Gateway"} firstRow>
            <div className={classes.iframeContainer}><iframe className={classes.videoFrame} width="620" height="325" src={"https://www.youtube.com/embed/0XaeCJ08d0Y"} allowFullScreen title="Setting up a new Allorado Gateway"></iframe></div>
          </CollapseRow>
          
          <CollapseRow title={"How do I add a new LoRa device"}>
            <div className={classes.iframeContainer}><iframe className={classes.videoFrame} width="620" height="325" src={"https://www.youtube.com/embed/WzDh2Yez-fY"} allowFullScreen title="How do I add a new LoRa device"></iframe></div>
          </CollapseRow>

          <CollapseRow title={"How do I use NodeRED"}>
            <div className={classes.iframeContainer}><iframe className={classes.videoFrame} width="620" height="325" src={"https://www.youtube.com/embed/zLjZshtDxWE"} allowFullScreen title="How do I use NodeRED"></iframe></div>
          </CollapseRow>
          
          <CollapseRow title={"How to: LoRa to KNX"}>
            <div className={classes.iframeContainer}><iframe className={classes.videoFrame} width="620" height="325" src={"https://www.youtube.com/embed/k3rCugcxZME"} allowFullScreen title="How to: LoRa to KNX"></iframe></div>
          </CollapseRow>

          <CollapseRow title={"How to: LoRa to BACnet"}>
            [Video under production]
          </CollapseRow>

          <CollapseRow title={"How to: LoRa to Modbus"}>
            [Video under production]
          </CollapseRow>

          <CollapseRow title={"How to: LoRa to dashboard"} lastRow>
            [Video under production]
          </CollapseRow>
        </div>

        <div className={classes.docContainer}>
          <h1>Documentation</h1>
          <div className={classes.docTable}>
            <div className={classes.docRow}><NavLink className={classes.docLink} to={"/docs/specification-sheet"}>1. Specification sheet</NavLink></div>
            <div className={classes.docRow}><NavLink className={classes.docLink} to={"/docs/getting-started"}>2. Getting started</NavLink></div>
            <div className={classes.docRow}><NavLink className={classes.docLink} to={"/docs/status-leds"}>3. Status LEDs</NavLink></div>
            <div className={classes.docRow}><NavLink className={classes.docLink} to={"/docs/logging-in"}>4. Logging in</NavLink></div>
            <div className={classes.docRow}><NavLink className={classes.docLink} to={"/docs/networking"}>5. Networking</NavLink></div>
            <div className={classes.docRow}><NavLink className={classes.docLink} to={"/docs/installing-license"}>6. Installing license</NavLink></div>
            <div className={classes.docRow}><NavLink className={classes.docLink} to={"/docs/updating-library"}>7. Updating decoding library</NavLink></div>
            <div className={classes.docRow}><NavLink className={classes.docLink} to={"/docs/testing-lora"}>8. Testing LoRa device</NavLink></div>
            <div className={classes.docRow}><NavLink className={classes.docLink} to={"/docs/lora-nodes"}>9. LoRa nodes</NavLink></div>
            <div className={classes.docRow}><NavLink className={classes.docLink} to={"/docs/knx-nodes"}>10. KNX nodes</NavLink></div>
            <div className={classes.docRow}><NavLink className={classes.docLink} to={"/docs/using-bacnet"}>11. Using BACnet</NavLink></div>
            <div className={classes.docRow}><NavLink className={classes.docLink} to={"/docs/example-flows"}>12. Example flows</NavLink></div>
          </div>
        </div>

        <div className={classes.docContainer}>
          <h1>Community</h1>
          <p>Have a question? Feature request? Get help from <a href="https://community.allorado.io" className={classes.inlineLink} target="_blank" rel="noreferrer"><b>our community</b></a>.</p>
        </div>
      </div>
    </div>
  );
}

export default Docs;
