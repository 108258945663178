import React from "react";
import classes from "./DocPage.css"
import { NavLink } from "react-router-dom";

const DocPage = (props) => {

  return (
    <div className={classes.pageContainer}>
      <div className={classes.docTable}>
        <h3>Table of contents</h3>
        <div className={classes.docRow}><NavLink className={classes.docLink} activeClassName={classes.activePage} to={"/docs/specification-sheet"}>1. Specification sheet</NavLink></div>
        <div className={classes.docRow}><NavLink className={classes.docLink} activeClassName={classes.activePage} to={"/docs/getting-started"}>2. Getting started</NavLink></div>
        <div className={classes.docRow}><NavLink className={classes.docLink} activeClassName={classes.activePage} to={"/docs/status-leds"}>3. Status LEDs</NavLink></div>
        <div className={classes.docRow}><NavLink className={classes.docLink} activeClassName={classes.activePage} to={"/docs/logging-in"}>4. Logging in</NavLink></div>
        <div className={classes.docRow}><NavLink className={classes.docLink} activeClassName={classes.activePage} to={"/docs/networking"}>5. Networking</NavLink></div>
        <div className={classes.docRow}><NavLink className={classes.docLink} activeClassName={classes.activePage} to={"/docs/installing-license"}>6. Installing license</NavLink></div>
        <div className={classes.docRow}><NavLink className={classes.docLink} activeClassName={classes.activePage} to={"/docs/updating-library"}>7. Updating decoding library</NavLink></div>
        <div className={classes.docRow}><NavLink className={classes.docLink} activeClassName={classes.activePage} to={"/docs/testing-lora"}>8. Testing LoRa device</NavLink></div>
        <div className={classes.docRow}><NavLink className={classes.docLink} activeClassName={classes.activePage} to={"/docs/lora-nodes"}>9. LoRa nodes</NavLink></div>
        <div className={classes.docRow}><NavLink className={classes.docLink} activeClassName={classes.activePage} to={"/docs/knx-nodes"}>10. KNX nodes</NavLink></div>
        <div className={classes.docRow}><NavLink className={classes.docLink} activeClassName={classes.activePage} to={"/docs/using-bacnet"}>11. Using BACnet</NavLink></div>
        <div className={classes.docRow}><NavLink className={classes.docLink} activeClassName={classes.activePage} to={"/docs/example-flows"}>12. Example flows</NavLink></div>
      </div>
      <div className={classes.docContainer}>
        <div className={classes.titleRow}>
          <h1>{props.title}</h1>
          <a href={props.link} target="_blank" rel="noreferrer" download className={classes.downloadButton}>Download as PDF</a>
        </div>
        {props.children}
      </div>
    </div>
  );
}

export default DocPage;
