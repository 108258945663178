import React from "react"
import ReactGA from "react-ga4"
import { NavLink } from "react-router-dom"
import ProtocolMapper from "./ProtocolMapper/Mapper"

import GatewayPhoto from "../../containers/UI/Gateway.png"
import GatewayFull from "./images/GatewayFull.png"
import FatArrow from "../../containers/UI/FatArrow.svg"
import Decoding from "./images/Decoding.png"
import NodeRED from "./images/NodeRED.png"

import Instagram from "./images/Instagram.svg"
import LinkedIn from "./images/LinkedIn.svg"
import Twitter from "./images/Twitter.svg"
import YouTube from "./images/YouTube.svg"
import BACnet from "./images/BACnet.png"
import KNX from "./images/KNX.png"

import meet from "./meet.css"
import why from "./why.css"
import lora from "./lora.css"
import community from "./community.css"
import features from "./features.css"
import conclusion from "./conclusion.css"


const Gateway = (props) => {

  const protocols = [
    { name: "BACnet", image: BACnet, link: "/lora-to-bacnet", text: "With Allorado, LoRa data appears natively on the BACnet network with only a few clicks. Enter the keys of your LoRa device, select its manufacturer from our library and select which parameters should be visible in BACnet. Allorado gets it done." },
    { name: "KNX", image: KNX, link: "/lora-to-knx", text: "With Allorado, LoRa data appears natively on the KNX bus with only a few clicks. Enter the keys of your LoRa device, select its manufacturer from our library and select which parameters should be mapped to group addresses. Allorado gets it done." },
  ]

  return (
    <>
    <div className={meet.container}>
      <div className={meet.contentContainer}>
        <div className={meet.leftContainer}>
          <h1 className={meet.title}>Meet the <br/><span className={meet.boldTitle}>Allorado Gateway.</span></h1>
          <div className={meet.subtitleContainer}>
            <div className={meet.subtitleRow}>• Enables effortless LoRa communication</div>
            <div className={meet.subtitleRow}>• Plug and play <span style={{ fontWeight: "bold"}}>LoRa</span> to <span style={{ fontWeight: "bold"}}>BACnet</span></div>
            <div className={meet.subtitleRow}>• A limitless toolbox thanks to NodeRED</div>
          </div>
        </div>
        <div className={meet.rightContainer}>
          <img className={meet.gatewayPhoto} src={GatewayPhoto} alt="Allorado Gateway"/>
        </div>
      </div>
      <div className={meet.bottomBorder}></div>
    </div>


    <div className={why.contentContainer}>
      <div className={why.title}>Why <span style={{ fontWeight: "bold"}}>Allorado</span>?</div>
      <div className={why.rowContainer}>
        <div className={why.subcontainer}>
          <h1>Speed</h1>
          <p>Integrate faster with our<br/>built-in device library</p>
        </div>
        <div className={why.subcontainer}>
          <h1>Power</h1>
          <p>Leverage the power of the<br/>community in NodeRED</p>
        </div>
        <div className={why.subcontainer}>
          <h1>Reliability</h1>
          <p>Based on industrial<br/>hardware by Siemens</p>
        </div>
      </div>
      <div className={meet.bottomBorder}></div>
    </div>

    <div className={lora.container}>
      <div className={lora.contentContainer}>
        <div className={lora.leftContainer}>
          <h1 className={lora.title}><span className={lora.boldTitle}>LoRa,</span><br/>easier than ever</h1>
          <div className={lora.subtitleContainer}>
            Allorado offers integrated decoding for different vendors and hundreds of devices. Bought a new device? Get going instantly. The decoded messages arrive in NodeRED, where you can process the data further to fulfill almost any scenario you can imagine.
          </div>
          <div className={lora.consultRow}>
            <NavLink className={lora.consultButton} to={"/software"} onClick={() => ReactGA.event({ category: "Button", action: "Gateway to decoder library" })}>Consult the library</NavLink>
            <img src={FatArrow} className={lora.arrow} alt="arrow"/>
          </div>
        </div>
        <div className={lora.rightContainer}>
          <img className={lora.decodingImage} src={Decoding} alt="Allorado Gateway"/>
        </div>
      </div>
      <div className={meet.bottomBorder}></div>
    </div>

    <ProtocolMapper protocols={protocols}/>

    <div className={community.container}>
      <div className={community.contentContainer}>
        <div className={community.leftContainer}>
          <h1 className={community.title}>Let the community<br/><span className={community.boldTitle}>empower you</span></h1>
          <div className={community.subtitleContainer}>
            The Allorado gateway leverages the power of the NodeRED community and extends it with LoRa decoding nodes. Send an email, connect to KNX, transfer over FTP to a server? Simply install the corresponding open-source node and get going.
          </div>
        </div>
        <div className={community.rightContainer}>
          <img className={community.decodingImage} src={NodeRED} alt="NodeRED flows"/>
        </div>
      </div>
      <div className={meet.bottomBorder}></div>
    </div>

   

    <div className={features.contentContainer}>
      <div className={features.title}><span style={{ fontWeight: "bold"}}>More features</span> in a nutshell</div>
      <div className={features.rowContainer}>
        <div className={features.subcontainer}>
          <h1>Private</h1>
          <p>Allorado creates your own private LoRa network. No public provider, all traffic stays private.</p>
        </div>
        <div className={features.subcontainer}>
          <h1>Modern</h1>
          <p>Allorado is built on all the latest technologies. Enter the next-generation LoRa gateway.</p>
        </div>
        <div className={features.subcontainer}>
          <h1>Industrial</h1>
          <p>The gateway comes with a DIN rail mounting. External antennas are also available.</p>
        </div>
      </div>
    </div>

    <div className={conclusion.contentContainer}>
      <div className={conclusion.title}>Full speed ahead, with <span style={{ fontWeight: "bold"}}>Allorado</span>.</div>
      <div className={conclusion.imageContainer}>
        <img className={conclusion.gatewayImage} src={GatewayFull} alt="Allorado Gateway"/>
      </div>
      <div className={conclusion.buttonContainer}>
        <button className={conclusion.contact} onClick={() => { ReactGA.event({ category: "Button", action: "Get in touch" }); window.location = "mailto:sales@allorado.io?subject=Interested in Allorado" }}>Get in touch</button>
        <NavLink className={conclusion.order} to={"/order"} onClick={() => ReactGA.event({ category: "Button", action: "Gateway to order page" })}>Order</NavLink>
      </div>

      <div className={conclusion.footerContainer}>
        <div className={conclusion.copyrightContainer}>
          <p className={conclusion.copyright}>Copyright © {new Date().getFullYear()} Allorado BV</p>
          <p className={conclusion.copyright}>Product images © {new Date().getFullYear()} Siemens AG</p>
        </div>
        <div className={conclusion.socialContainer}>
          <img onClick={() => { ReactGA.event({ category: "Button", action: "LinkedIn" }); window.open("https://www.linkedin.com/company/allorado", "_blank") }} className={conclusion.socialIcon} src={LinkedIn} alt="LinkedIn logo"/>
          <img onClick={() => { ReactGA.event({ category: "Button", action: "YouTube" }); window.open("https://www.youtube.com/channel/UCAIq8RWLwBDIT_lyO8SBONA", "_blank") }} className={conclusion.socialIcon} src={YouTube} alt="YouTube logo"/>
          <img onClick={() => { ReactGA.event({ category: "Button", action: "Instagram" }); window.open("https://www.instagram.com/allorado.io", "_blank") }} className={conclusion.socialIcon} src={Instagram} alt="Instagram logo"/>
          <img onClick={() => { ReactGA.event({ category: "Button", action: "Twitter" }); window.open("https://twitter.com/allorado_io", "_blank") }} className={conclusion.socialIcon} src={Twitter} alt="Twitter logo"/>
        </div>
        <div style={{width: "250px"}}></div>
      </div>
    </div>
    </>
  )
}

export default Gateway;
