import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ScrollToTop from "./containers/Navigation/ScrollToTop.js";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import TagManager from 'react-gtm-module'

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import authReducer from "./store/reducers/auth";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create a root reducer
const rootReducer = combineReducers({ auth: authReducer, });

// Create the store
const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

// Create the app
const app = (
  <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
  </Provider>
);

// Setup tag manager
TagManager.initialize({ gtmId: "GTM-WKHXW6H" })

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
