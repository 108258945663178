import React from "react";
import classes from "../Style.css"
import DocPage from "../DocPage/DocPage"

const DocumentationPage = (props) => {

  return (
    <>
      <DocPage title={"Testing a new LoRa device"} link={"https://gateway.allorado.io/docs/8.%20Testing%20LoRa%20device.pdf"}>
        <div className={classes.docContainer}>
          <p>When setting up a new LoRa device, it can be difficult to evaluate whether the device works. Allorado has a <b>Live Traffic view</b> to assist you. This real-time view will show you when a device comes online and when it transmits data.</p>

          <h3>Testing procedure</h3>
          <ol>
            <li>Obtain the keys (DevEUI, AppEUI, AppKey) from the device manufacturer</li>
            <li>Log into your Allorado Gateway</li>
            <li>Head over to the “LoRa devices” tab</li>
            <li>Add a new device, name it, insert its key and select the correct model</li>
            <li>Save the device</li>
            <li>Head over to the “Live Traffic” tab</li>
            <li>Activate the device by inserting the battery or pressing its reset button. </li>
            <div className={classes.listIntermezzo}><b>Note</b>: Make sure it isn't connected to any other nearby network (by removing it from other gateways)</div>
            <li>You'll see the device join on the live traffic view. Click the balloon for more information</li>
            <li>Some devices transmit data immediately, others wait for a bit before sending their first data</li>
            <li>If the device is in the library, decoded values will appear when clicking the blue balloon</li>
         </ol>

         After verifying the device works with this testing procedure, create a flow in NodeRED or automatically map its data to BACnet or MQTT on the web interface to get the most out of your LoRa device.
        </div>
      </DocPage>
    </>
  );
}

export default DocumentationPage;
