import React from "react";
import ReactGA from "react-ga4";
import Visual from "../Visuals/Visual 7.jpeg";
import commonClasses from "../ArticleStyle.css"
import classes from "./light-building.css"
import FatArrow from "../../../containers/UI/FatArrow.svg"

const News = (props) => {

  return (
    <>
      <div className={commonClasses.container}>

        <div className={commonClasses.innerContainer}>
          <h3 className={commonClasses.dateLabel}>Frankfurt, March 8th 2024</h3>
          <h1>Allorado makes debut at Light+Building</h1>
          
          <div className={classes.visualContainer}>
            <img src={Visual} className={commonClasses.visual} alt="Indumation the award ceremony"/>
          </div>

          <p className={commonClasses.contentParagraph}>Allorado proudly marks a significant milestone by participating for the first time at the renowned Light+Building fair in Frankfurt. Positioned close to the KNX Association in Hall 12.0, Allorado stands out as the only startup from the KNX Startup Incubator with its own dedicated booth.</p>
          <p className={commonClasses.contentParagraph}>The Light+Building fair, the beating heart of the KNX world, offers Allorado the perfect platform to demonstrate its LoRa capabilities and introduce new LoRa + KNX functionality. Visitors have the opportunity experience firsthand exactly how easy Allorado makes LoRa and discover how LoRa + KNX can take their projects to the next level.</p>
          
          <div className={commonClasses.callToAction}>
            <h3>Interested in LoRa + KNX?</h3>
            <div className={commonClasses.orderRow}>
              <button className={commonClasses.orderButton} onClick={() => {
                  ReactGA.event({ category: "Button", action: "News item to contact" })
                  window.location = "mailto:sales@allorado.io?subject=Interested in LoRa + KNX"
                }}>Get in touch today</button>
              <img src={FatArrow} className={commonClasses.arrow} alt="arrow"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
