import React from "react"
import { NavLink } from "react-router-dom"
import classes from "./News.css"
import Visual1 from "./1-first-distributor/MCS-visual.png"
import Visual2 from "./Visuals/Visual 2.png"
import Visual3 from "./3-campus-contern/Contern visual.png"
import Visual4 from "./Visuals/Visual 4.png"
import Visual5 from "./Visuals/Visual 5.svg"
import Visual6 from "./Visuals/Visual 6.jpg"
import Visual7 from "./Visuals/Visual 7.jpeg"
import Visual9 from "./Visuals/Visual 9.jpeg"
import Visual10 from "./Visuals/Visual 10.png"
import Visual11 from "./Visuals/Visual 11.png"


const News = (props) => {

  const posts = [
    {
      type: "INTERVIEW",
      date: "June 19th 2024",
      title: "KNX DIALogue series talk about Allorado + KNX",
      image: Visual11,
      link: "knx-dialogue-series"
    },
    {
      type: "NEWS UPDATE",
      date: "May 30th 2024",
      title: "Futurasmus becomes distributor and partner",
      image: Visual10,
      imageStyle: classes.mcsVisual,
      link: "futurasmus-distributor"
    },
    {
      type: "NEWS UPDATE",
      date: "May 23rd 2024",
      title: "Allorado shines at IoT Solutions World Congress",
      image: Visual9,
      link: "iotswc-2024"
    },
    {
      type: "NEWS UPDATE",
      date: "April 12th 2024",
      title: "Introducing KNX on the Allorado Gateway",
      image: Visual5,
      link: "introducing-knx"
    },
    {
      type: "NEWS UPDATE",
      date: "March 8th 2024",
      title: "Allorado makes debut at Light+Building",
      image: Visual7,
      link: "light-building-2024"
    },
    {
      type: "NEWS UPDATE",
      date: "February 1st 2024",
      title: "Allorado wins the Siemens Best Application Contest",
      image: Visual6,
      link: "siemens-bac"
    },
    {
      type: "REFERENCE CASE",
      date: "December 7th 2023",
      title: "Reference: Campus Contern",
      image: Visual3,
      link: "campus-contern"
    },
    {
      type: "NEWS UPDATE",
      date: "October 18th 2023",
      title: "Allorado joins the KNX Startup Incubator",
      image: Visual5,
      link: "knx-startup-incubator"
    },
    {
      type: "USE CASE",
      date: "October 2nd 2023",
      title: "Upgrading radiators with smart thermostatic valves",
      image: Visual4,
      link: "smart-heating"
    },
    {
      type: "NEWS UPDATE",
      date: " May 15th 2023",
      title: "Introducing BACnet on the Allorado Gateway",
      image: Visual2,
      imageStyle: classes.bacnetVisual,
      link: "introducing-bacnet"
    },
    {
      type: "NEWS UPDATE",
      date: " March 22nd 2023",
      title: "MCS signs up as first international distributor",
      image: Visual1,
      imageStyle: classes.mcsVisual,
      link: "first-distributor"
    }
  ]

  const Post = (props) => {
    return (
      <>
        <NavLink className={classes.postContainer} to={"/news/" + props.link}>
          <div className={classes.imageContainer}>
            <img src={props.image} className={props.imageStyle} alt="Article cover"/>
          </div>
          <div className={classes.textContainer}>
            <h3 className={classes.typeLabel}>{props.type}</h3>
            <p className={classes.titleLabel}>{props.title}</p>
            <h3 className={classes.dateLabel}>{props.date}</h3>
          </div>
        </NavLink>
      </>
    );
  }
  

  // Convert the post array to JSX
  var postRows = []
  var postToJSX = (post) => { 
    console.log(post)
    return <Post type={post.type} date={post.date} title={post.title} image={post.image} imageStyle={post.imageStyle} link={post.link}/> }

  for (let i = 0; i < posts.length; i += 2) {
    postRows.push(
      <div className={classes.postRow}>
        {postToJSX(posts[i])}
        {((i+1) < posts.length) && postToJSX(posts[i+1])}
      </div>
    )
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <h1>Latest news</h1>
          {postRows}
        </div>
        <div className={classes.copyright}><b>[1]</b> BACnet™ is a trademark of ASHRAE <br/><b>[2]</b> Startup logo © {new Date().getFullYear()} KNX Association cvba</div>
      </div>
    </>
  );
}

export default News;
