import React from "react";
import ReactGA from "react-ga4";
import Logo from "../Visuals/Visual 5.svg";
import Visual from "./KNX Scientific.jpeg";
import { NavLink } from "react-router-dom";
import commonClasses from "../ArticleStyle.css"
import classes from "./knx-incubator.css"
import FatArrow from "../../../containers/UI/FatArrow.svg"

const News = (props) => {

  return (
    <>
      <div className={commonClasses.container}>

        <div className={commonClasses.innerContainer}>
          <h3 className={commonClasses.dateLabel}>Tervuren, October 18th 2023</h3>
          <h1>Allorado joins the KNX Startup Incubator</h1>

          <div className={classes.visualContainer}>
            <img src={Logo} className={commonClasses.visual} alt="KNX Startup logo."/>
          </div>

          <p className={commonClasses.contentParagraph}>We are excited to announce that Allorado has joined the <a href="https://www.knx.org/knx-en/for-manufacturers/startup/" className={commonClasses.inlineLink} target="_blank" rel="noreferrer">KNX Startup Incubator</a>, an initiative by KNX Association to help companies innovate quickly and cooperate with KNX community members. The Startup Incubator was launched in 2021 as an initiative to get new KNX technology into the hands of disruptive startups as fast as possible. The current focus of the incubator is KNX IoT, a new and rich complement to the classic KNX specifications. Thanks to this innovation, KNX is ready to enter the era of IoT.</p>
          <p className={commonClasses.contentParagraph}>Allorado is leading the next generation of Internet-of-Things with groundbreaking integrations of protocols both new and old, so joining the KNX Startup Incubator only made sense. As an incubatee, KNX Association provides us with tools, equipment and unique showcase opportunities. We are thrilled to start integrating KNX IoT into our product line-up!</p>
          
          <p className={commonClasses.contentSubtitle}>Our journey with KNX Association</p>
          <div className={classes.conferenceContainer}>
            <img src={Visual} className={commonClasses.visual} alt="KNX CTO Joost Demarest shaking the hand of Allorado CEO Bram Roelandts at the KNX Scientific Conference 2018."/>
            <p className={commonClasses.imageCaption}>Award ceremony of the KNX Scientific Conference 2018</p>
          </div>
          <p className={commonClasses.contentParagraph}>The story didn’t quite start with Allorado joining the KNX Startup Incubator. Co-founder Filip has been an avid KNX enthusiast for decades and got our CEO Bram interested in KNX at age fifteen. The two collaborated on an iPhone visualization for smart homes called SimplySmart for two years. At age seventeen, Bram won top honors for the paper he presented at the KNX Scientific Conference 2018. Allorado emerged after the Scientific Conference, from taking the solution presented there to the next level. What evolved is the Allorado Gateway.</p>

          <div className={commonClasses.callToAction}>
            <h3>More on how Allorado came to be?</h3>
            <div className={commonClasses.orderRow}>
              <NavLink className={commonClasses.orderButton} to={"/story"} onClick={() => ReactGA.event({ category: "Button", action: "News item to story" })}>Check out our story</NavLink>
              <img src={FatArrow} className={commonClasses.arrow} alt="arrow"/>
            </div>
          </div>
        </div>

        <div className={commonClasses.footnote}>Logo and photograph © {new Date().getFullYear()} KNX Association cvba - <a href="https://www.knx.org/" className={commonClasses.inlineLink} target="_blank" rel="noreferrer">www.knx.org</a></div>
      </div>
    </>
  );
}

export default News;
