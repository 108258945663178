import React from "react";
import classes from "../Style.css"
import DocPage from "../DocPage/DocPage"

const DocumentationPage = (props) => {

  return (
    <>
      <DocPage title={"Logging in for the first time"} link={"https://gateway.allorado.io/docs/4.%20Logging%20in.pdf"}>
        <div className={classes.docContainer}>
          <p>The magic of the Allorado Gateway happens on its web interface. This page is accessible on the local network through your internet browser.</p>
          
          <h3>Login procedure</h3>

          <ol>
            <li>Find the gateway’s IP address (on your router or using an arp-scan)</li>
            <li>Open the web interface in your browser by surfing to <span className={classes.inlineCode}>http://[your IP address]</span></li>
            <li>You'll be presented with the login page. The default login credentials:</li>
            <ul className={classes.sublist}>
              <li><b>Username:</b> admin</li>
              <li><b>Password:</b> admin</li>
            </ul>
            <li>Navigate to “System Configuration” and change the admin password</li>
          </ol>

          <div className={classes.standaloneTip}>
            <div><b>Tip</b>:</div>
            <div>Accessing NodeRED happens using the same credentials as the web interface.</div>
          </div>
        </div>
      </DocPage>
    </>
  );
}

export default DocumentationPage;
