import React from "react";
import ReactGA from "react-ga4";
import Visual from "../Visuals/Visual 6.jpg";
import { NavLink } from "react-router-dom";
import commonClasses from "../ArticleStyle.css"
import classes from "./siemens-bac.css"
import FatArrow from "../../../containers/UI/FatArrow.svg"

const News = (props) => {

  return (
    <>
      <div className={commonClasses.container}>

        <div className={commonClasses.innerContainer}>
          <h3 className={commonClasses.dateLabel}>Kortijk, February 1st 2024</h3>
          <h1>Allorado wins the Siemens Best Application Contest</h1>
          
          <div className={classes.visualContainer}>
            <img src={Visual} className={commonClasses.visual} alt="Indumation the award ceremony"/>
            <p className={commonClasses.imageCaption} style={{fontSize: "15px"}}>Allorado team with Siemens CFO, SVP Digital Industries & Business Unit Manager Automation</p>
          </div>

          <p className={commonClasses.contentParagraph}>In the Fall of 2023, Allorado entered the Siemens Best Application as one of thirteen cases shaping the future of automation by using Siemens products in an innovative way. The contest was organized by the Digital Industries entity of Siemens Belgium to highlight how Siemens products lead the way in industrial automation. From sorting books to growing mushrooms more efficiently and from single-person startups to giants like Johnson & Johnson and Volvo Car, the contest presented thirteen diverse cases from thirteen just as diverse companies. Allorado, although among the smallest of the participants, firmly held its own with its compelling application: the Allorado Gateway. Read the full case <a href="https://www.siemens.com/be/nl/products/automatisering/best-application-contest/projects/allorado-lora-gateway-iot2050.html" className={commonClasses.inlineLink} target="_blank" rel="noreferrer">in Dutch</a> or <a href="https://www.siemens.com/be/fr/products/automatisation/best-application-contest/projects/allorado-lora-gateway-iot2050.html" className={commonClasses.inlineLink} target="_blank" rel="noreferrer">in French</a></p>
          <p className={commonClasses.contentParagraph}>On February 1st, the award ceremony took place at Indumation in Kortrijk. While all thirteen participants were convinced of their victory, ultimately only one could take first place and win the €5.000 grand prize. Allorado was fortunate enough to end up in first place and gladly accepted the award presented by Siemens CFO Ilse Geukens, SVP Digital Industries Thierry Van Eeckhout and Business Unit Manager Industrial Automation Geert De Coninck (left to right). A major victory for Allorado just one month into 2024, and this is only the beginning. Read the <a href="https://press.siemens.com/be/nl/persbericht/iot-specialist-allorado-wint-siemens-best-application-contest" className={commonClasses.inlineLink} target="_blank" rel="noreferrer">full press release</a> on the Siemens website.</p>
          
          <div className={commonClasses.callToAction}>
            <h3>More on how Allorado came to be?</h3>
            <div className={commonClasses.orderRow}>
              <NavLink className={commonClasses.orderButton} to={"/story"} onClick={() => ReactGA.event({ category: "Button", action: "News item to story" })}>Check out our story</NavLink>
              <img src={FatArrow} className={commonClasses.arrow} alt="arrow"/>
            </div>
          </div>
        </div>

        <div className={commonClasses.footnote}>Photograph © {new Date().getFullYear()} Siemens NV</div>
      </div>
    </>
  );
}

export default News;
