import React from "react";
import classes from "../Style.css"
import DocPage from "../DocPage/DocPage"
import Figure1 from "../Figures/9-1.png"
import Figure2 from "../Figures/9-2.png"
import Figure3 from "../Figures/9-3.png"

const DocumentationPage = (props) => {

  return (
    <>
      <DocPage title={"The LoRa nodes in NodeRED"} link={"https://gateway.allorado.io/docs/9.%20LoRa%20nodes.pdf"}>
        <div className={classes.docContainer}>
          <p>Allorado provides the user with three LoRa-related nodes in NodeRED. These nodes merely interact with LoRa devices, their provisioning happens solely on the web interface (under “LoRa devices”).</p>

          <ul>
            <li>LoRa <b>uplink</b>: sends out a message when the LoRa device transmits a message</li>
            <li>LoRa <b>event</b>: sends out event-related messages, like device joined or downlink confirmed</li>
            <li>LoRa <b>downlink</b>: allows users to schedule a downlink to a LoRa device</li>
          </ul>

          <h3>1. LoRa uplink node</h3>
          <p>The uplink node returns messages sent out by LoRa devices. In the configuration of the node, users can select whether they want messages from all devices or one specific device. The documentation tab in NodeRED provides information on the format of resulting output messages, visible in the figure below.</p>
          <img style={{ maxHeight: "380px" }} className={classes.structureImage} src={Figure1} alt="Output message format of the LoRa uplink node."/>

          <h3>2. LoRa event node</h3>
          <p>The event node does not handle direct messages to and from the LoRa device, but rather focusses on the transport around the messages. Two events are currently implemented:</p>
          <ul>
            <li><b>Join</b>: sent when a LoRa device comes online and joins the LoRa network</li>
            <li><b>Confirm</b>: sent when a LoRa device acknowledges a downlink message</li>
          </ul>
          <p>When sending a downlink, the user can request for the downlink to be acknowledged. This flag can be set both in the Downlink node and when sending a downlink from the web interface. If the device supports acknowledgements, it will reply with an acknowledgement when properly received. To keep track of which downlink is meant exactly, the user can pass a “reference” property when sending the downlink. At confirm, the Event node includes this reference so the downlink can be identified.</p>

          <div className={classes.standaloneTip} style={{marginTop: "5px"}}>
            <div><b>Note</b>:</div>
            <div>Requesting acknowledgement results in reduced battery life in the LoRa device.</div>
          </div>

          <p>The documentation tab in NodeRED provides information on the format of resulting output messages, visible in the figure below.</p>
          <img style={{ maxHeight: "260px" }} className={classes.structureImage} src={Figure2} alt="Output message format of the LoRa event node."/>


          <h3>3. LoRa downlink node</h3>

          <p>The Downlink node allows users to send downlink messages to LoRa devices, for configuration or control purposes. For convenience, this node can be both configured from the node editor and by passing parameters in the input message. Settings included in the input message <b>take priority</b> over the node editor.</p>
          <p>The <b>confirm</b> parameter can be set to request confirmation of the downlink. If the device supports acknowledgements, it will reply with an acknowledgement when properly received. Note that requesting acknowledgement results in reduced battery life in the LoRa device.</p>
          <p>A <b>reference</b> string can be passed to keep track of downlinks. When the message is acknowledged by the device, the event node returns a “confirm” event which includes this reference.</p>
          <p>The parameter <b>overwriteQueue</b> allows users to clear the downlink queue for a certain device. Most often, multiple downlinks are put into a queue and sent one by one. For certain applications, the previous queue needs to be erased and the injected downlink needs to be the one and only scheduled downlink. An example is setting a thermostat setpoint temperature. You only want to transmit the latest temperature setpoint, older setpoints have become irrelevant to transmit.</p>

          <img style={{ maxHeight: "240px" }} className={classes.structureImage} src={Figure3} alt="Input message format of the LoRa downlink node."/>

        </div>
      </DocPage>
    </>
  );
}

export default DocumentationPage;
