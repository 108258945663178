import React from "react";
import Discover from "./Discover/Discover"
import Building from "./Building/Building"

import classes from "./Gateway.css"

class Gateway extends React.Component {

  fields = ["Energy management", "Building", "Dashboard", "HVAC"]
  state = {
    selectedCase: 2,
    discover: false
  }

  // MARK: - Constructor
  constructor() {
    super()
    this.discoverRef = React.createRef()
  }

  // MARK: - Discover page

  changeCaseSelection = (newCase) => {
    this.setState({ selectedCase: newCase })
  }
  discoverAllorado = () => {
    this.setState({ discover: true })
    setTimeout(() => this.scroll(this.discoverRef), 10)

  }
  scroll = (ref) => {
    // console.log(ref)
    ref.current.scrollIntoView({ behavior: "smooth" })
  }



  render() {

    return (
      <div className={classes.pageContainer}>
        <Discover discoverMore={this.discoverAllorado} changeCase={this.changeCaseSelection} fields={this.fields}/>
        {this.state.discover && <Building field={this.fields[this.state.selectedCase]} innerRef={this.discoverRef}/>}
      </div>
    )
  }
}

export default Gateway;
