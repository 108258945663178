import React from "react";

import NavigationHeader from "../Navigation/NavigationHeader/NavigationHeader";
import classes from "./Layout.css";


const layout = (props) => {

  // Determine scroll overflow color
  let backgroundColor = "white"
  switch (window.location.pathname) {
    case "/docs":
    case "/news": 
    case "/software": 
      backgroundColor = "#f5f5f7"; break;
    default: backgroundColor = "white"; break;
  }

  return (
    <>
      <NavigationHeader
        isAuthenticated={props.isAuthenticated}
        username={props.username}
        logout={props.logout}/>
        <main className={classes.content + " mainContainer"} style={{backgroundColor: backgroundColor}}>
          {props.children}
        </main>
    </>
  );
}

export default layout;
