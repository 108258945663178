import React from "react";
import classes from "../Style.css"
import DocPage from "../DocPage/DocPage"

const DocumentationPage = (props) => {

  return (
    <>
      <DocPage title={"Installing a license"} link={"https://gateway.allorado.io/docs/6.%20Installing%20license.pdf"}>
        <div className={classes.docContainer}>
          <p>A lifetime license comes preinstalled on gateways assembled by Allorado. Installing a license by yourself is <b>only necessary</b> after a software update or when upgrading to a more capable license.</p>

          <h3>Installing a new license</h3>

          <ol>
            <li>Obtain a .pem license file from Allorado</li>
            <li>Log into the web interface and navigate to “System Configuration”</li>
            <li>Click “Install license” and upload the .pem file</li>
            <li>After installing, the gateway's capabilities will be activated immediately</li>
          </ol>
        </div>
      </DocPage>
    </>
  );
}

export default DocumentationPage;
