import React from "react";
import classes from "../Style.css"
import DocPage from "../DocPage/DocPage"
import { NavLink } from "react-router-dom";

// Images
import Figure1 from "../Figures/2-1.png"

const DocumentationPage = (props) => {

  return (
    <>
      <DocPage title={"Getting started"} link={"https://gateway.allorado.io/docs/2.%20Getting%20started.pdf"}>
        <div className={classes.docContainer}>
        <p>This document guides you in setting up your Allorado Gateway.</p>

        <h3>Hardware setup</h3>
        <ol>
          <li>Unbox your Allorado Gateway</li>
          <li>Connect an RP-SMA antenna to the antenna connector</li>
            <div className={classes.listIntermezzo}><b>Warning</b>: Never run your gateway without an antenna or a proper 50Ω termination. If the radio starts transmitting without an antenna, the power cannot be properly dissipated and the radio might suffer permanent damage.</div>
          <li>Connect the gateway to a DC power source between 12 and 24 volts</li>
          <img className={classes.listImage} src={Figure1} alt="Connection diagram of DC power supply to IOT2050 power plug."/>
          <li>Connect a networking cable to the gateway, either to your local network or directly to your computer. For more information on networking, consult our <NavLink className={classes.docLink} to={"/docs/networking"}>“Networking”</NavLink> guide.</li>
        </ol>

        <h3>Software setup</h3>
        The Allorado software and a valid license are preinstalled on your gateway when it is delivered to you. 

        <ol>
          <li>Log into your Allorado Gateway, with the help of our <NavLink className={classes.docLink} to={"/docs/logging-in"}>“Logging in”</NavLink> guide</li>
          <li>Add your first LoRa device, with the help of our <NavLink className={classes.docLink} to={"/docs/testing-lora"}>“Testing LoRa device”</NavLink> guide</li>
          <li>Find useful resources on using your Allorado Gateway in:</li>
          <ul className={classes.sublist}>
            <li>The <NavLink className={classes.docLink} to={"/docs/lora-nodes"}>“LoRa nodes”</NavLink> guide</li>
            <li>The <NavLink className={classes.docLink} to={"/docs/knx-nodes"}>“KNX nodes”</NavLink> guide</li>
            <li>The <NavLink className={classes.docLink} to={"/docs/using-bacnet"}>“Using BACnet”</NavLink> guide</li>
          </ul>
          <li>Find useful example flows for your specific goal in our <NavLink className={classes.docLink} to={"/docs/example-flows"}>“Example flows”</NavLink> guide</li>
        </ol>
        </div>
      </DocPage>
    </>
  );
}

export default DocumentationPage;
