import React from "react";
import Modal from "../../../containers/UI/Modal/Modal";
import CollapseRow from "../../../containers/UI/CollapseRow/CollapseRow";
import classes from "./Antennae.css"

const changelog = (props) => {



  return (
    <Modal shouldShow={props.shouldShow} closeModal={props.closeModal} mediumwide>
      
      <div className={classes.container}>
      
        <div className={classes.headerRow}>
          <h3>Available antennae</h3>
          <button className={classes.closeButton} onClick={props.closeModal}>Close</button>
        </div>

        <CollapseRow title={"Basic antenna"} firstRow subtle>
          <ul>
            <li>868 MHz omnidirectional antenna</li>
            <li>Length: 195 mm</li>
            <li>Screws directly onto your gateway</li>
          </ul>
        </CollapseRow>
        <CollapseRow title={"Through-hole antenna 2 dBi"} subtle>
          <ul>
            <li>868 MHz omnidirectional antenna</li>
            <li>Passive gain: 2 dBi</li>
            <li>Dimensions: 77 mm x 15 mm</li>
            <li>Rating: IP69</li>
            <li>Cables can be built to order</li>
            <li>For more info, contact<button className={classes.mailButton} onClick={() => window.location = "mailto:sales@allorado.io?subject=Antenna-related inquiry"}>sales@allorado.io</button></li>
          </ul>
        </CollapseRow>
        <CollapseRow title={"Magnetically mounted antenna 2.6 dBi"} subtle>
          <ul>
            <li>868 MHz omnidirectional antenna</li>
            <li>Passive gain: 2.6 dBi</li>
            <li>Magnetic base, ideal to mount onto metal cabinet</li>
            <li>Dimensions: 91 mm x 31 mm</li>
            <li>Cables can be built to order</li>
            <li>For more info, contact<button className={classes.mailButton} onClick={() => window.location = "mailto:sales@allorado.io?subject=Antenna-related inquiry"}>sales@allorado.io</button></li>
          </ul>
        </CollapseRow>
        <CollapseRow title={"Wall mounted antenna 3.2 dBi"} subtle>
          <ul>
            <li>868 MHz omnidirectional antenna</li>
            <li>Passive gain: 3.2 dBi</li>
            <li>Metal wall mount included</li>
            <li>UV hardened, exterior mounting possible</li>
            <li>Total dimensions: 271 mm x 30 mm x 115 mm</li>
            <li>Antenna dimensions: 219 mm x ⌀19 mm</li>
            <li>Cables can be built to order</li>
            <li>For more info, contact<button className={classes.mailButton} onClick={() => window.location = "mailto:sales@allorado.io?subject=Antenna-related inquiry"}>sales@allorado.io</button></li>
          </ul>
        </CollapseRow>
        <CollapseRow title={"Fiberglass antenna 8 dBi"} lastRow subtle>
          <ul>
            <li>868 MHz omnidirectional antenna</li>
            <li>Passive gain: 8 dBi</li>
            <li>Metal wall mount included</li>
            <li>Exterior mounting possible</li>
            <li>Antenna dimensions: 1145 mm x ⌀34 mm</li>
            <li>Cables can be built to order</li>
            <li>For more info, contact<button className={classes.mailButton} onClick={() => window.location = "mailto:sales@allorado.io?subject=Antenna-related inquiry"}>sales@allorado.io</button></li>
          </ul>
        </CollapseRow>
      </div>
    </Modal>
  );
}

export default changelog;
