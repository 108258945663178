import React from "react";
import ReactGA from "react-ga4";
import Visual from "./Visual.png";
import commonClasses from "../ArticleStyle.css"
import classes from "./smart-heating.css"
import FatArrow from "../../../containers/UI/FatArrow.svg"

const News = (props) => {
  
  return (
    <>
      <div className={commonClasses.container}>

        <div className={commonClasses.innerContainer}>
          <h3 className={commonClasses.dateLabel}>Tervuren, October 2nd 2023</h3>
          <h1>Upgrading radiators with smart LoRa thermostatic valves</h1>
          
          <div className={classes.visualContainer}>
            <img src={Visual} className={commonClasses.visual} alt="BACnet visual"/>
          </div>

          <p className={commonClasses.contentParagraph}>
            The importance of having a fully automated heating solution cannot be underestimated. Nonetheless, upgrading a traditional heating radiator presents its own set of challenges. You need to follow the energy reduction legislation of Europe, but you also don’t want to break the bank by extensive renovations or construction. What you need is a cost effective and efficient heating solution and Allorado can help you with that.</p>
          
          <div className={classes.challengeContainer}>
            <p className={classes.challengeHeader}>The challenge is clear:</p>
            <p className={classes.challengeContent}>How do you introduce advanced heating capabilities without undertaking extensive rennovations? </p>
          </div>
          
          <p className={commonClasses.contentParagraph}>
            What’s our solution? Well, we recommend replacing the radiator valve with a smart thermostatic valve that integrates with the HVAC installation, completely wirelessly. In case you don’t know: HVAC stands for heating, ventilation and air conditioning.</p>

          <p className={commonClasses.contentSubtitle}>Addressing challenges</p>
          <p className={commonClasses.contentParagraph}>This is the perfect solution in most cases. However, that doesn’t mean that it doesn’t have any challenges. For example:
            <ul>
              <li>The HVAC controller has to be able to communicate with the new thermostatic valves. This controller often only supports BACnet as a communication protocol.</li>
              <li>Also, the valves need to work in an energy efficient battery powered way so that the maintenance is reduced to a minimum.</li>
              <li>Last but not least, the wireless connection needs to be secure and must allow for deep indoor coverage - especially in older buildings that might have complex layouts.</li>
            </ul>
          </p>

          <p className={commonClasses.contentSubtitle}>Allorado's solution: bridging the gap</p>
          <p className={commonClasses.contentParagraph}>
            Luckily, Allorado bringst the ideal solution for these challenges. With Allorado, the gap between the HVAC system and the heating valve is bridged, as the Allorado gateway allows you to deploy a private LoRa network on premise. Our gateway handles the protocol conversion, which makes wireless smart heating valves directly compatible with existing, decades-old BACnet heating installations. Isn’t that neat? </p>
          <p className={commonClasses.contentParagraph}>
            Once the network is installed, you can easily adjust the temperature. The HVAC controller sends a setpoint value to the smart valves, which can drive the radiator head with the built-in battery powered motor. The ambient temperature can come from already installed temperature probes or additionally deployed LoRa temperature sensors.</p>
          <p className={commonClasses.contentParagraph}>
            Moreover, the LoRa valves available on the market will last for several years using energy harvesting to ensure that the battery is recharged, resulting in minimal maintenance.</p>
          <p className={commonClasses.contentParagraph}>
            Lastly, LoRa communication is secure by design, encrypted with AES-128.</p>

          <div className={commonClasses.callToAction}>
            <h3>Ready to start heating efficiently?</h3>
            <h4>Are you considering a heating upgrade that's both energy-efficient and compatible with existing infrastructure? If so, <b>don't hesitate to reach out</b> to Allorado.</h4>
            <div className={commonClasses.orderRow}>
              <button className={commonClasses.orderButton} onClick={() => {
                  ReactGA.event({ category: "Button", action: "News item to contact" })
                  window.location = "mailto:sales@allorado.io?subject=Upgrading my building"
                }}>Contact us today to explore the possibilities</button>
              <img src={FatArrow} className={commonClasses.arrow} alt="arrow"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
