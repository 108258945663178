import React, {  useEffect } from "react";
import classes from "./Story.css"
import FoundersFront from "./Images/Founders-front.jpg"
import FoundersBack from "./Images/Founders-back.jpg"
import Bram from "./Images/Bram.png"
import Filip from "./Images/Filip.png"


const Story = (props) => {

  // Define images
  let frontImage = null
  let backImage = null
  let frontShown = false

  // Helpers
  let showFront = () => {
    frontShown = true
    frontImage.classList.remove(classes.invisible)
    backImage.classList.add(classes.invisible)
  }
  let showBack = () => {
    frontShown = false
    frontImage.classList.add(classes.invisible)
    backImage.classList.remove(classes.invisible)
  }

  // Define a scroll handler
  let handleScroll = (event) => {

    // Determine a multiplier for small screens
    let width = window.screen.width
    let multiplier = 1

    if (width < 390) {
      multiplier = 7
    } else if (width < 430) {
      multiplier = 6
    } else if (width < 550) {
      multiplier = 5
    } else if (width < 750) {
      multiplier = 4
    } else if (width < 950) {
      multiplier = 2
    }


    // Check if we have references
    if (frontImage == null) {
      frontImage = document.getElementById("frontImage")
    }
    if (backImage == null) {
      backImage = document.getElementById("backImage")
    }

    if (frontImage && backImage) {
      const position = event.target.scrollTop
      // Hysteresis threshold
      if (frontShown === true && position < 20*multiplier) {
        showBack()
      } else if (frontShown === false && position > 35*multiplier) {
        showFront()
      }
    }
  }

  // Setup a listener
  useEffect(() => {
    // Get the scroll container
    let mainContainer = document.getElementsByClassName("mainContainer")[0]
    // Add event listener
    mainContainer.addEventListener("scroll", handleScroll, { passive: true })
    // Return a cleanup function
    return () => { mainContainer.removeEventListener("scroll", handleScroll)}
  })


  return (
    <>
      <div className={classes.topContainer}>
        <div className={classes.introductionContainer}>
          <p>Allorado is a story of new talent, relentless creativity and crystal-clear vision in partnership with vast knowledge, seasoned experience, and an eye for recognizing serious potential.</p>
          <h1>Meet Bram and Filip.</h1>
        </div>
        <div className={classes.foundersContainer}>
          <div className={classes.leftColumn}>
            <div className={classes.founderIntro} style={{paddingLeft: "20px"}}>
              <p className={classes.founderName}>Bram Roelandts</p>
              <p className={classes.founderTitle}>CEO, co-founder & lead visionary</p>
            </div>
          </div>
          <div className={classes.centralColumn}>
            <img className={classes.foundersPhoto} src={FoundersBack} alt="Both Allorado founders together, from the back" id="backImage"/>
            <img className={classes.foundersPhoto + " " + classes.invisible} src={FoundersFront} alt="Both Allorado founders together, from the front" id="frontImage"/>
          </div>
          <div className={classes.rightColumn}>
            <div className={classes.founderIntro}>
              <p className={classes.founderName}>Filip Van Craenenbroeck</p>
              <p className={classes.founderTitle}>Co-founder & wisdom keeper</p>
            </div>
          </div>
      </div>
      </div>

      <div className={classes.founderRow}>
        <div className={classes.founderBio}>
          <h1>Bram Roelandts</h1>
          <h3>CEO, co-founder & lead visionary</h3>
          <p>Bram started experimenting with software at the age of eight. He was into Linux distros and web design at twelve and fell in love with coding at ﬁfteen. During high school, Bram and Filip developed SimplySmart, a modern iPhone app for KNX smart homes. 2018 was busy: Bram won ﬁrst prize for the paper he presented at the KNX Scientiﬁc Conference in Vienna, and he attended Apple's WWDC developers' conference in California, as one of 350 carefully selected students worldwide. Then came university. Bram graduated cum laude from KU Leuven in 2024 with an MSc in Electrical Engineering. Now finally graduated from university, his full potential is set to be unleashed. When he’s offscreen, he might well be rock climbing.</p>
        </div>
        <div className={classes.founderPhoto}>
          <img src={Bram} alt="Portrait of Bram Roelandts"/>
        </div>
      </div>

      <div className={classes.founderRow}>
        <div className={classes.founderPhoto}>
          <img src={Filip} alt="Portrait of Filip Van Craenenbroeck"/>
        </div>
        <div className={classes.founderBio}>
          <h1>Filip Van Craenenbroeck</h1>
          <h3>Co-founder & wisdom keeper</h3>
          <p>Filip Van Craenenbroeck has close to 30 years’ experience in IT, experience that allowed him to recognize Bram’s prodigious talent and startling maturity. Inspired, he became Bram’s mentor. Filip is an engineer with a strong technical, program management and pre-sales background. After working for companies including Alcatel, Motorola and Cisco, in 2010 he started his own independent consulting company, 5A. His focus there for the past decade has been providing embedded solutions within the IoT world. His greatest pride and satisfaction come from working with Bram, launching cutting-edge products and a game-changing young entrepreneur.</p>
        </div>
      </div>

      <div className={classes.conclusionContainer}>

        <h1><b>Allorado</b>, the next-generation LoRa gateway</h1>
        <p>Allorado emerged after the 2018 KNX Scientiﬁc Conference, from taking the solution we presented there to the next level. What evolved is the Allorado Gateway. It allows users to easily complement their existing building installations with data from LoRa sensors.</p>
        <p>Although LoRa is a secure, long-range wireless protocol maintained by LoRa Alliance, it doesn't standardize data sent by devices the way protocols like KNX do. The Allorado Gateway solves this by carefully decoding bits and bytes sent from the LoRa network to telegrams that other installations - KNX, BACnet, Modbus and more - can understand. With Allorado, users can easily enrich their building or factory with a myriad of LoRa sensors, making LoRa more accessible and easier to use than ever before.</p>
      </div>
    </>
  );
}

export default Story;
