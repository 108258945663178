import React, { Component } from "react";
import ReactGA from "react-ga4";
import Manufacturer from "./Manufacturer/Manufacturer"
import Changelog from "./Changelog/Changelog"
import classes from "./Software.css"
import axios from "axios"


class Software extends Component {

  state = {
    supportedDevices: {},
    releases: [],
    lastUpdated: "unknown",
    showAll: false,
    // Changelog dialog
    showDialog: false,
    changelog: null,
    version: null,
  }

  componentDidMount() {

    // Download supported devices
    axios.get("https://gateway.allorado.io/SupportedDevices.json")
      .then((result) => {
        this.setState({ supportedDevices: result.data })
      })

    // Download decoder versions
    axios.get("https://gateway.allorado.io/DecoderInfo.json")
      .then((result) => {

        let releaseArray = []
        let releases = result.data.releaseDates
        let changelog = result.data.changelog
        let versions = Object.keys(releases)

        // Sort version numbers
        // Split once and sort then (more efficient than handling splitting in the sort)
        let largestPossibleVersion = 100000
        versions = versions.map( a => a.split('.').map( n => + n + largestPossibleVersion).join('.') ).sort().map( a => a.split('.').map( n => + n - largestPossibleVersion).join('.') );
  
        versions.reverse()
        
        // Map object to array
        for (let version of versions) {
          releaseArray.push({ date: releases[version], changelog: changelog[version], version: version })
        }
        this.setState({
          releases: releaseArray,
          lastUpdated: result.data.releaseDates[versions[0]] })
        // Output: [{ number, date}, ...]
      })
  }

  render() {

    // MARK: - Supported devices
    let devicesContent = []
    if (this.state.supportedDevices) {
      let keys = Object.keys(this.state.supportedDevices)
      const lastIndex = keys.length - 1
      keys.sort()
      for (const [i, key] of keys.entries()) {
        // Construct device list
        let deviceRows = []
        for (let device of this.state.supportedDevices[key]) {
          deviceRows.push(<li key={device}>{device}</li>)
        }
        // Save manufacturer
        devicesContent.push(<Manufacturer manufacturer={key} devices={deviceRows} key={key} firstRow={i === 0} lastRow={i === lastIndex} />)
      }
    }

    // MARK: - Decoder downloads
    let decoderDownloads = []
    if (this.state.releases) {

      let length = this.state.releases.length;
      for (var index = 0; index < length; index++) {
        let release = this.state.releases[index]
        decoderDownloads.push(
          <div className={classes.versionRow} key={release.version}>
            <p className={classes.versionNumber}><span className={classes.versionText}>Version </span>{release.version}</p>
            <p className={classes.releaseDate}>{release.date}</p>
            <button className={classes.changeLink} onClick={() => this.setState({ version: release.version, changelog: release.changelog, showDialog: true })}>Changelog</button>
            <a className={classes.downloadLink} href={"https://gateway.allorado.io/allorado-decoders-" + release.version + ".deb"} download onClick={() => ReactGA.event({ category: "Button", action: "Download decoder deb" })}>Download</a>
          </div>)
        // Show more/less versions
        if (index === 4 && this.state.showAll === false) {
          break
        }
      }
    }

    return (
      <div className={classes.container}>
        <div className={classes.innerContainer}>

          <div className={classes.downloadContainer}>
            <div className={classes.titleRow}>
              <h1>Downloads</h1>
              <button className={classes.moreButton} onClick={() => this.setState({ showAll: !this.state.showAll })}>show {this.state.showAll ? "less" : "all"} versions</button>
            </div>

            <div className={classes.downloadSubcontainer}>
              {decoderDownloads}
            </div>
          </div>

          <div className={classes.videoContainer}>
            <div className={classes.titleContainer}>
              <h1 className={classes.supportedTitle}>Supported LoRa devices</h1>
              <p className={classes.lastUpdated}>Last updated {this.state.lastUpdated}</p>
            </div>

            {devicesContent}
          </div>
        </div>
        <Changelog
          shouldShow={this.state.showDialog}
          version={this.state.version}
          changelog={this.state.changelog}
          closeModal={() => this.setState({ showDialog: false })}/>
      </div>
    );
  }
}

export default Software;
