import React from "react";
import classes from "./Building.css"
import GatewayPhoto from "../../../containers/UI/Gateway.png"

const Building = (props) => {

  return (
    <div className={classes.buildingContainer} ref={props.innerRef}>

      <div className={classes.welcomeContainer}>
        <div className={classes.leftWelcome}>
          <h3 className={classes.meetAllo}>Meet the Allorado Gateway.</h3>
          <div className={classes.keywordContainer}>
            <div className={classes.column}><div className={classes.keyword}>Speed</div>      <div className={classes.keyphrase}>Integrate faster with our<br />built-in device library</div></div>
            <div className={classes.column}><div className={classes.keyword}>Power</div>      <div className={classes.keyphrase}>Leverage the power of the<br />community in NodeRED</div></div>
            <div className={classes.column}><div className={classes.keyword}>Reliability</div><div className={classes.keyphrase}>Based on industrial<br />hardware by Siemens</div></div>
          </div>
        </div>
        <div className={classes.rightWelcome}>
          <img className={classes.gatewayPhoto} src={GatewayPhoto} alt="Allorado Gateway"/>
        </div>
      </div>

    </div>
  )
}

export default Building;
