import React from "react";
import classes from "../Style.css"
import DocPage from "../DocPage/DocPage"
import Figure1 from "../Figures/10-1.png"
import Figure2 from "../Figures/10-2.png"
import Figure3 from "../Figures/10-3.png"
import Figure4 from "../Figures/10-4.png"
import Figure5 from "../Figures/10-5.png"
import Figure6 from "../Figures/10-6.png"
import Figure7 from "../Figures/10-7.png"
import Figure8 from "../Figures/10-8.png"
import Figure9 from "../Figures/10-9.png"

const DocumentationPage = (props) => {

  return (
    <>
      <DocPage title={"The KNX nodes in NodeRED"} link={"https://gateway.allorado.io/docs/10.%20KNX%20nodes.pdf"}>
        <div className={classes.docContainer}>
          <p>Allorado provides the user with five KNX-related nodes in NodeRED:</p>
          
          <ul>
            <li>KNX <b>Read</b>: sends a read request on the bus, hoping for a reply</li>
            <li>KNX <b>Write</b>: sends a write request on the bus</li>
            <li>KNX <b>Encode</b>: encodes a human-readable value into bytes (to write further on)</li>
            <li>KNX <b>Decode</b>: decodes bytes into a human-readable value (from a listen earlier on)</li>
            <li>KNX <b>Listen</b>: listens for telegrams on the KNX bus sent by other parties</li>
          </ul>

          <h3>1. KNX Read node</h3>
          <p>This node sends a read request for a specific group address onto the KNX bus. Not all KNX devices support read requests and the read-flag needs to be enabled from ETS. If the requested group address is connected to a KNX device, the KNX device will respond and that response will appear in the KNX Listen node. The input message structure:</p>
          <img style={{ maxHeight: "65px" }} className={classes.structureImage} src={Figure1} alt="Input message format of the KNX Read node."/>

          <h3>2. KNX Write node</h3>
          <p>This node writes a value for a specific group address onto the KNX bus. The node expects data in Buffer format (a JavaScript byte representation). To avoid dealing with bits and bytes yourself, use the KNX Encode node in conjunction with this node, as pictured below:</p>
          <img style={{ maxHeight: "65px" }} className={classes.flowImage} src={Figure2} alt="NodeRED flow demonstrating KNX Write node."/>
          <p>Input message structure:</p>
          <img style={{ maxHeight: "100px" }} className={classes.structureImage} src={Figure3} alt="Input message format of the KNX Write node."/>


          <h3>3. KNX Encode node</h3>
          <p>This node converts human-readable values into bytes, according to the DPT (KNX data point type, as specified in <a className={classes.docLink} href={"https://www.knx.org/wAssets/docs/downloads/Certification/Interworking-Datapoint-types/03_07_02-Datapoint-Types-v02.02.01-AS.pdf"}>section 3.7.2</a> of the KNX specs). Writing values to the KNX bus happens in bytes. For the convenience of the user, the Encode node transforms values into the correct byte representation based on the DPT the user specifies. The output of this node can be injected directly into a KNX Write node.</p>
          <img style={{ maxHeight: "65px" }} className={classes.flowImage} src={Figure4} alt="NodeRED flow demonstrating KNX Encode node."/>
          <p>Input message structure:</p>
          <img style={{ maxHeight: "170px" }} className={classes.structureImage} src={Figure5} alt="Input message format of the KNX Encode node."/>
          <p>The DPTs have a wide range of value types (string, integer, float, object and more). Refer to the documentation within NodeRED for information on the input format of your data. Documentation can be viewed by clicking the Encode node and selecting the “Help” tab on the right.</p>


          <h3>4. KNX Decode node</h3>
          <p>What the Encode node does before writing to the bus, the Decode node does after reading from the bus. The Decode node translates a raw byte value into a human-readable value based on the provided DPT (KNX data point type, as specified in <a className={classes.docLink} href={"https://www.knx.org/wAssets/docs/downloads/Certification/Interworking-Datapoint-types/03_07_02-Datapoint-Types-v02.02.01-AS.pdf"}>section 3.7.2</a> of the KNX specs).</p>
          <p>This node is typically used in conjunction with the Listen node and a function node. The Listen node provides the raw data from the bus, the function node is needed to insert the correct DPT based on the received group address. It is up to the user to store information about group addresses and their corresponding DPT.</p>
          <img style={{ maxHeight: "65px" }} className={classes.flowImage} src={Figure6} alt="NodeRED flow demonstrating KNX Decode node."/>
          <p>Input message structure:</p>
          <img style={{ maxHeight: "170px" }} className={classes.structureImage} src={Figure7} alt="Input message format of the KNX Decode node."/>
          <p>The DPTs have a wide range of value types (string, integer, float, object and more). Refer to the documentation within NodeRED for information on the decoded format of your data. Documentation can be viewed by clicking the Decode node and selecting the “Help” tab on the right.</p>

          <h3>5. KNX Listen node</h3>
          <p>This node outputs telegrams sent on the KNX bus, so users can take appropriate action in NodeRED. On the configuration panel of the node (opens after double-clicking), the node can be configured to only output messages from a specific KNX device, to a specific group address or message type.</p>
          <p>This node is typically used in conjunction with the Listen node and a function node. The Listen node provides the raw data from the bus, the function node is needed to insert the correct DPT based on the received group address. It is up to the user to store information about group addresses and their corresponding DPT.</p>
          <img style={{ maxHeight: "65px" }} className={classes.flowImage} src={Figure8} alt="NodeRED flow demonstrating KNX Listen node."/>
          <p>Output message format:</p>
          <img style={{ maxHeight: "140px" }} className={classes.structureImage} src={Figure9} alt="Output message format of the KNX Listen node."/>
          
        </div>
      </DocPage>
    </>
  );
}

export default DocumentationPage;
