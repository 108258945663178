import React, { Component } from "react";
import ReactGA from "react-ga4";
import classes from "./Order.css"
import GatewayFull from "../1-gateway/images/GatewayFull.png"
import FatArrow from "../../containers/UI/FatArrow.svg"
import AntennaeDialog from "./Antennae/Antennae"

class Order extends Component {

  state = {
    antennaIncluded: false,
    psuIncluded: false
  }

  // Handlers
  includeAntenna = (event) => { this.setState({ antennaIncluded: event.target.checked })}
  includePSU = (event) => { this.setState({ psuIncluded: event.target.checked })}


  render () {

    return (
      <div className={classes.container}>  
        <div>
          <h1 className={classes.title}>Order your <span className={classes.boldTitle}>Allorado Gateway.</span></h1>
          <h3 className={classes.basePriceLabel}>Available from €1.149</h3>

          <div className={classes.panelContainer}>
            <div className={classes.leftPanel}>
              <img className={classes.gatewayImage} src={GatewayFull} alt="Allorado Gateway"/>
            </div>

            <div className={classes.rightPanel}>
              <div className={classes.priceCategory}>
                <div className={classes.categoryTitle}>Gateway</div>
                <div className={classes.priceTable}>
                  <table>
                    <tr><td></td><td>Allorado Gateway</td><td>€999</td></tr>
                    <tr><td></td><td>LoRa radio</td><td>€150</td></tr>
                  </table>
                  <div className={classes.baseLicenseLabel}>Includes 250 LoRa devices, 1 protocol and 1 forwarder</div>
                </div>
              </div>
              <hr className={classes.divider}/>
              <div className={classes.priceCategory}>
                <div className={classes.categoryTitle}>Software</div>
                <table className={classes.priceTable}>
                  <tr><td></td><td>External forwarders</td><td style={{fontSize: "16px"}}> €79 / forwarder</td></tr>
                  <tr><td></td><td>Extra protocols</td><td style={{fontSize: "16px"}}> €199 / protocol</td></tr>
                  <tr><td></td><td>Extra 100 devices</td><td style={{fontSize: "16px"}}> €279 / bundle</td></tr>

                </table>
              </div>
              <hr className={classes.divider}/>
              <div className={classes.priceCategory}>
                <div className={classes.categoryTitle}>Accessories</div>
                <div className={classes.accessoryContainer}>
                  <table className={classes.accessoryTable}>
                  <tr><td><input type="checkbox" value={this.state.antennaIncluded} onChange={this.includeAntenna} className={classes.checkbox}/></td><td>Basic antenna</td><td>€30</td></tr>
                  <tr><td><input type="checkbox" value={this.state.psuIncluded} onChange={this.includePSU} className={classes.checkbox}/></td><td>Power supply <span style={{ fontSize: "15px"}}>(DIN rail)</span></td><td>€30</td></tr>
                  </table>
                  <div className={classes.antennaeRow}><button className={classes.antennaeButton} onClick={() => { this.setState({ showAntennae: true }); ReactGA.event({ category: "Button", action: "Order to antennae" }) }}>More antennae available</button><img src={FatArrow} className={classes.arrow} alt="arrow"/></div>
                </div>
              </div>
              <hr className={classes.totalDivider}/>
              <div className={classes.totalRow}>
                <p className={classes.totalLabel}>Total, before VAT</p>
                <p className={classes.totalPrice}>€ 1.{(149 + 30 * this.state.antennaIncluded + 30 * this.state.psuIncluded)} <span style={{fontSize: "15px", fontWeight: "normal"}}>MSRP</span></p>
              </div>
              <div className={classes.orderRow}>
                <button className={classes.orderButton} onClick={() => {
                  ReactGA.event({ category: "Button", action: "Order" })
                  window.location = "mailto:sales@allorado.io?subject=Placing an Allorado order"
                }}>Order</button>
                <button className={classes.distributorButton} onClick={() => {
                  ReactGA.event({ category: "Button", action: "DistributorBrochure" })
                  window.location = "mailto:sales@allorado.io?subject=Becoming an Allorado distributor"
                }}>Become a distributor</button>
              </div>
            </div>
          </div>
        </div>
        
        <AntennaeDialog
          shouldShow={this.state.showAntennae}
          closeModal={() => this.setState({ showAntennae: false })}/>

        <div className={classes.copyright}>Product image © {new Date().getFullYear()} Siemens AG</div>
      </div>
    );
  }
}

export default Order;
