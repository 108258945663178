import React from "react";
import ReactGA from "react-ga4";
import commonClasses from "../ArticleStyle.css"
import classes from "./knx-dialogue.css"
import FatArrow from "../../../containers/UI/FatArrow.svg"

const News = (props) => {

  return (
    <>
      <div className={commonClasses.container}>

        <div className={commonClasses.innerContainer}>
          <h3 className={commonClasses.dateLabel}>Tervuren, June 19th 2024</h3>
          <h1>KNX DIALogue series</h1>
          
          <div className={classes.iframeContainer}><iframe className={classes.videoFrame} width="620" height="325" src={"https://www.youtube.com/embed/mPBsSlOA79o"} allowFullScreen title="KNX DIALogue series interview recording"></iframe></div>

          <p className={commonClasses.contentParagraph}>Allorado was featured as a guest in the prestigious KNX DIALogue series, where our CEO was interviewed by Bob Snyder. The discussion delved into Allorado's innovative LoRa - KNX integration, exploring the purpose, challenges, and future ambitions. This insightful conversation provided listeners with a unique perspective on what Allorado has in store regarding KNX IoT.</p>
          
          <div className={commonClasses.callToAction}>
            <h3>Interested in LoRa + KNX?</h3>
            <div className={commonClasses.orderRow}>
              <button className={commonClasses.orderButton} onClick={() => {
                  ReactGA.event({ category: "Button", action: "News item to contact" })
                  window.location = "mailto:sales@allorado.io?subject=Interested in LoRa + KNX"
                }}>Get in touch today</button>
              <img src={FatArrow} className={commonClasses.arrow} alt="arrow"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
