import React from "react";
import classes from "../Style.css"
import DocPage from "../DocPage/DocPage"

const DocumentationPage = (props) => {

  return (
    <>
      <DocPage title={"Meaning of the status LEDs"} link={"https://gateway.allorado.io/docs/3.%20Status%20LEDs.pdf"}>
        <div className={classes.docContainer}>
          <p>The gateway has four status lights. Their meaning is defined as below.</p>

          <h3>POWER light</h3>
          <ul>
            <li>Green when power is applied</li>
          </ul>
          
          <h3>STAT light</h3>
          <ul>
            <li>Blinks green when the gateway has loaded the operating system</li>
            <li>Solid red when the gateway has failed to load an operating system</li>
          </ul>

          <h3>USER1 light</h3>
          <ul>
            <li>No color: system watchdog disabled (by default)</li>
            <li>Green: gateway has fully booted correctly</li>
            <li>Orange: gateway tries to recover from a system error</li>
            <li>Red: gateway will reboot soon to recover</li>
          </ul>

          <h3>USER2 light</h3>
          <ul>
            <li>Free for program by users using the dedicated NodeRED node</li>
            <li>Supports green, orange and red colors</li>
          </ul>
        </div>
      </DocPage>
    </>
  );
}

export default DocumentationPage;
