import React from "react";
import ReactGA from "react-ga4";
import Visual from "./MCS-visual.png";
import commonClasses from "../ArticleStyle.css"
import classes from "./first-distributor.css"
import FatArrow from "../../../containers/UI/FatArrow.svg"

const News = (props) => {
  
  return (
    <>
      <div className={commonClasses.container}>

        <div className={commonClasses.innerContainer}>
          <h3 className={commonClasses.dateLabel}>Tervuren, March 22nd 2023</h3>
          <h1>MCS signs up as first international distributor</h1>
          
          <div className={classes.visualContainer}>
            <img src={Visual} className={commonClasses.visual} alt="BACnet visual"/>
          </div>

          <p className={commonClasses.contentParagraph}>We are thrilled to share that Allorado has signed up a first European distributor. Starting today, Allorado and <a href="https://www.mcs-nl.com" className={commonClasses.inlineLink} target="_blank" rel="noreferrer">MCS</a> enter a non-exclusive distribution agreement. MCS is a leading Dutch IoT distributor, founded in 1997. This is a major milestone for Allorado as we continue to bring our innovative product to the market in The Netherlands and Europe.</p>
          <p className={commonClasses.contentParagraph}>As part of the agreement, MCS will be the first distributor to take our LoRa gateway to market through their channels. We will supply the product to them, while they sell it under their own name as a white-labeled product.</p>
          <p className={commonClasses.contentParagraph}>We are excited about this partnership with MCS and the opportunities it presents for us to reach new customers and markets. We look forward to making further progress towards our goals.</p>
          
          <div className={commonClasses.callToAction}>
            <h3>Want to become part of the action?</h3>
            <div className={commonClasses.orderRow}>
              <button className={commonClasses.orderButton} onClick={() => {
                  ReactGA.event({ category: "Button", action: "DistributorBrochure" })
                  window.location = "mailto:sales@allorado.io?subject=Becoming an Allorado distributor"
                }}>Become a distributor</button>
              <img src={FatArrow} className={commonClasses.arrow} alt="arrow"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
