import React from "react";
import ReactGA from "react-ga4";
import FatArrow from "../../containers/UI/FatArrow.svg"
import Step1 from "./visuals/Step 1.png";
import Step2 from "./visuals/Step 2.png";
import Step3 from "./visuals/Step 3.png";
import Step4 from "./visuals/Step 4.png";

import { NavLink } from "react-router-dom";
import classes from "./bacnet.css"

const News = (props) => {
  
  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Meet the <span className={classes.boldTitle}><span className={classes.titleBreak}><br/></span>BACnet mapper</span>.</h1>
      
      <div className={classes.rightStepContainer}>
        <div className={classes.imageContainer}><img className={classes.image} src={Step1} alt="Device configuration panel"/></div>
        <div className={classes.titleContainer}><h3>1. Add your LoRa device</h3></div>
      </div>

      <div className={classes.leftStepContainer}>
      <div className={classes.titleContainer}><h3>2. Choose a BACnet device ID</h3></div>
        <div className={classes.imageContainer}><img className={classes.image} src={Step2} alt="BACnet device ID configuration panel"/></div>
      </div>

      <div className={classes.rightStepContainer}>
        <div className={classes.imageContainer}><img className={classes.image} src={Step3} alt="Configuration panel for choosing LoRa parameters to be mapped to BACnet"/></div>
        <div className={classes.titleContainer}><h3>3. Choose which parameters to make available</h3></div>
      </div>

      <div className={classes.step4Container}>
        <div className={classes.titleContainer}><h3>4. Deploy the resulting BACnet objects. Boom.</h3></div>
        <div className={classes.imageContainer}><img className={classes.fullImage} src={Step4} alt="Resulting BACnet objects in a table"/></div>
      </div>

      <div className={classes.actionContainer}>
        <h3 className={classes.actionTitle}>Transform your building.</h3>
        <div className={classes.orderRow}>
            <NavLink className={classes.orderButton} to={"/order"} onClick={() => ReactGA.event({ category: "Button", action: "BACnet page to order" })}>Get your Allorado now</NavLink>
            <img src={FatArrow} className={classes.arrow} alt="arrow"/>
        </div>
      </div>
    </div>
  );
}

export default News;
