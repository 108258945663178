import React from "react"
import { NavLink } from "react-router-dom";

import NavigationItem from "./NavigationItem/NavigationItem"
import classes from "./NavigationHeader.css"
import Logo from "./AlloradoLogo.png"

const navigationHeader = (props) => {



  return(
    <div className={classes.container}>
      <div className={classes.logoContainer}>
        <NavLink to={"/"}>
          <img className={classes.logo} src={Logo} alt="Allorado Gateway logo"/>
        </NavLink>
      </div>
      <div className={classes.itemContainer}>
        <NavigationItem title="Allorado" page="/gateway"/>
        <NavigationItem title="News" page="/news"/>
        <NavigationItem title="Our story" page="/story"/>
        <NavigationItem title="Resources" page="/docs"/>
        <NavigationItem title="Software" page="/software"/>
        <NavigationItem title="Order" page="/order" orderButton/>
      </div>
    </div>
  );
}

export default navigationHeader;
