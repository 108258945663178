import React from "react";
import ReactGA from "react-ga4";
import Visual from "./BACnet-visual.png";
import { NavLink } from "react-router-dom";
import commonClasses from "../ArticleStyle.css"
import classes from "./introducing-bacnet.css"
import FatArrow from "../../../containers/UI/FatArrow.svg"

const News = (props) => {
  
  return (
    <>
      <div className={commonClasses.container}>

        <div className={commonClasses.innerContainer}>
          <h3 className={commonClasses.dateLabel}>Tervuren, May 15th 2023</h3>
          <h1>Introducing BACnet <br/>on the Allorado Gateway</h1>
          
          <div className={classes.visualContainer}>
            <img src={Visual} className={commonClasses.visual} alt="BACnet visual"/>
          </div>

          <p className={commonClasses.contentParagraph}>Today marks the official release of Allorado software version 1.2. In addition to minor performance improvements, the central focus of this new release is the all-new <strong>BACnet functionality</strong>. After receiving numerous requests to connect LoRa with BACnet, we decided to take matters into our own hands and develop our own implementation from scratch.</p>
          <p className={commonClasses.contentParagraph}>Our team has put in countless hours to make this possible, refining every detail to ensure a seamless user experience.  Interacting with BACnet is possible in two ways:
            <ul>
              <li>An automated <b>mapper</b> on the web interface maps LoRa data to BACnet objects in minutes, without any manual input required from the user</li>
              <li>A BACnet <b>node</b> in NodeRED allows for advanced integration and creates endless possibilities.</li>
            </ul>
            The combination of these two features turns the Allorado Gateway into the most powerful LoRa to BACnet solution on the market: blazingly fast integration with the mapper, endless possibilities in NodeRED. To help you get started, we provide thorough <NavLink className={commonClasses.inlineLink} to="/docs/using-bacnet">BACnet documentation</NavLink> and intuitive <NavLink className={commonClasses.inlineLink} to="/docs/example-flows">example flows</NavLink>.
          </p>
          
          <div className={commonClasses.callToAction}>
            <h3>Ready to transform your building?</h3>
            <div className={commonClasses.orderRow}>
              <NavLink className={commonClasses.orderButton} to={"/order"} onClick={() => ReactGA.event({ category: "Button", action: "News item to order" })}>Order your Allorado today</NavLink>
              <img src={FatArrow} className={commonClasses.arrow} alt="arrow"/>
            </div>
          </div>
        </div>

        <div className={commonClasses.footnote}><strong>[1]</strong> BACnet™ is a trademark of ASHRAE</div>
      </div>
    </>
  );
}

export default News;
