import React from "react";
import Modal from "../../../containers/UI/Modal/Modal";
import classes from "./Changelog.css"

const changelog = (props) => {



  return (
    <Modal shouldShow={props.shouldShow} closeModal={props.closeModal}>
      <h3>Changes in release {props.version}</h3>

      <div className={classes.container}>
        {props.changelog}
      </div>
      <button className={classes.closeButton} onClick={props.closeModal}>Close</button>
    </Modal>
  );
}

export default changelog;
