import React from "react";
import ReactGA from "react-ga4";
import Visual from "../Visuals/Visual 10.png";
import commonClasses from "../ArticleStyle.css"
import classes from "./futurasmus.css"
import FatArrow from "../../../containers/UI/FatArrow.svg"

const News = (props) => {

  return (
    <>
      <div className={commonClasses.container}>

        <div className={commonClasses.innerContainer}>
          <h3 className={commonClasses.dateLabel}>Tervuren, May 30th 2024</h3>
          <h1>Futurasmus becomes Allorado distributor and partner</h1>
          
          <div className={classes.visualContainer}>
            <img src={Visual} className={commonClasses.visual} alt="Indumation the award ceremony"/>
          </div>

          <p className={commonClasses.contentParagraph}>Allorado is excited to announce a new partnership with Futurasmus, the leading KNX distributor in the Spanish market. Futurasmus will start distributing Allorado solutions across Spain, helping us expand our presence in this key market. This collaboration also includes opportunities for training, webinars, and sharing project expertise.</p>
          <p className={commonClasses.contentParagraph}>Beyond distribution, Allorado and Futurasmus will work closely together on joint developments in the building automation sector. By complementing each other’s strengths, the companies aim to deliver even more advanced solutions that meet the evolving needs of the Spanish market.</p>
          
          <div className={commonClasses.callToAction}>
            <h3>Want to become part of the action?</h3>
            <div className={commonClasses.orderRow}>
              <button className={commonClasses.orderButton} onClick={() => {
                  ReactGA.event({ category: "Button", action: "DistributorBrochure" })
                  window.location = "mailto:sales@allorado.io?subject=Becoming an Allorado distributor"
                }}>Become a distributor</button>
              <img src={FatArrow} className={commonClasses.arrow} alt="arrow"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
