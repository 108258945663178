import React, { Component } from "react"
import classes from "./Mapper.css";

import ReactGA from "react-ga4"
import { NavLink } from "react-router-dom"
import FatArrow from "../../../containers/UI/FatArrow.svg"
import meet from "../meet.css"
import lora from "../lora.css"

class Picker extends Component {

  state = {
    activeIndex: 0,
    animationState: "none" // out, in or none
  }

  switchoverTimerID = null


  componentDidMount() {
    this.startSwitchoverTimer()
  }

  render() {  

    // Generate bullets
    let bullets = this.props.protocols.map((protocol, index) => {
      return <li className={classes.item}>
        <button className={this.state.activeIndex === index ? classes.active : ""} onClick={() => this.manuallySwitchOver(index)}></button>
      </li>
    })
    
    return (

      <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className={classes.imageContainer}>
          <img className={classes.decodingImage + this.getAnimationClass()} src={this.props.protocols[this.state.activeIndex].image} alt="LoRa to mapper"/>
        </div>

        <div className={classes.wrapper}>
          <ul className={classes.pagination}>
            { bullets }
          </ul>
        </div>

        <div className={classes.textContainer + this.getAnimationClass()}>
          <h1 className={classes.title}>
            <span className={classes.boldTitle}>LoRa</span> to <span className={classes.boldTitle}>{ this.props.protocols[this.state.activeIndex].name }</span>,<br/>in a few clicks</h1>
          <div className={classes.subtitleContainer}>
            { this.props.protocols[this.state.activeIndex].text }
          </div>
          <div className={lora.consultRow}>
            <NavLink className={lora.consultButton} to={this.props.protocols[this.state.activeIndex].link } onClick={() => ReactGA.event({ category: "Button", action: "Discover BACnet" })}>Discover the { this.props.protocols[this.state.activeIndex].name } mapper</NavLink>
            <img src={FatArrow} className={lora.arrow} alt="arrow"/>
          </div>
        </div>
      </div>
      <div className={meet.bottomBorder}></div>
    </div>

    )
  }


  // MARK: - Animation helpers

  startSwitchoverTimer = () => {

    this.switchoverTimerID = setTimeout(() => {
      // Find next index
      let newIndex = this.state.activeIndex + 1
      if (newIndex === this.props.protocols.length) { newIndex = 0 }

      // Animate to new protocol
      this.animateProtocolChange(newIndex)
      this.startSwitchoverTimer()
    }, 5000)
  }

  manuallySwitchOver = (newIndex) => {
    clearTimeout(this.switchoverTimerID)
    if (newIndex !== this.state.activeIndex) {
      this.animateProtocolChange(newIndex)
    }
  }

  animateProtocolChange = (newIndex) => {
    // Animate everything out
    this.setState({ animationState: "out" })

    setTimeout(() => {
      // Change and animate in
      this.setState({ animationState: "in", activeIndex: newIndex })
    }, 200)
  }

  getAnimationClass = () => {
    switch (this.state.animationState) {
      case "out": return " " + classes.animateOut
      case "in": return " " + classes.animateIn
      default: return ""
    }
  }
}

export default Picker

