import React from "react";
import classes from "./Manufacturer.css"
import Collapsible from "react-collapsible";
import Arrow from "./arrow.svg"

const Manufacturer = (props) => {

  return (
    <Collapsible trigger={[props.manufacturer, <img className={classes.arrow} src={Arrow} alt=""/>]}
      transitionTime={300}
      key={props.manufacturer}
      className={classes.collapsibleItemClosed + (props.firstRow ? (" " + classes.firstRow) : "") + (props.lastRow ? (" " + classes.lastRow) : "")}
      openedClassName={classes.collapsibleItemOpen + (props.firstRow ? (" " + classes.firstRow) : "") + (props.lastRow ? (" " + classes.lastRow) : "")}
      triggerClassName={classes.header}
      triggerOpenedClassName={classes.header}>
      <div className={classes.collapsibleContent}>
        <ul>
          {props.devices}
        </ul>
      </div>
    </Collapsible>
  );
}

export default Manufacturer;
