import React from "react";
import WheelPicker from "./Picker/Picker"
import classes from "./Discover.css"

const Discover = (props) => {

  return (
    <div className={classes.discoverContainer}>
      <div className={classes.connectContainer}>
          <h1 className={classes.title}>Integrate <span>LoRa</span> devices in my</h1>
          <div className={classes.pickerContainer}>
            <WheelPicker
                data={props.fields}
                height={40}
                fontSize={24}
                defaultSelection={1}
                parentHeight={300}
                updateSelection={(selection) => { props.changeCase(selection) }}
                scrollerId="protocolPicker"
                animation={"flat"}
            />
          </div>
      </div>
      <button className={classes.discoverButton} onClick={props.discoverMore}>Discover Allorado</button>
    </div>
  )
}

export default Discover;
