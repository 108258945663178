import React from "react";
import { render } from "react-dom"
import classes from "./Alert.css";

const Modal = (props) => {


  // Actions
  let close = () => {
    render(<></>, document.getElementById("alert-view-container"));
  }
  let confirm = () => {
    close()
    if (props.confirmHandler) {
      props.confirmHandler()
    }
  }

  // Determine button type
  var buttonRow = (<button className={classes.okButton} onClick={confirm}>OK</button>)
  if (props.confirmDialog === true) {
    buttonRow = (<div className={classes.confirmRow}>
      <button className={classes.cancelButton} onClick={close}>Cancel</button>
      <button className={classes.confirmbutton} onClick={confirm}>Confirm</button>
    </div>)
  }

  return (
    <div>
      <div className={classes.backdrop} onClick={close}></div>
      <div className={classes.Modal}>
        <div className={classes.titleContainer}>
          {props.error && <embed className={classes.errorMark} src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIyNCIgdmVyc2lvbj0iMS4xIiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6Y2M9Imh0dHA6Ly9jcmVhdGl2ZWNvbW1vbnMub3JnL25zIyIgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgLTEwMjguNCkiPjxwYXRoIGQ9Im0yMiAxMmMwIDUuNTIzLTQuNDc3IDEwLTEwIDEwLTUuNTIyOCAwLTEwLTQuNDc3LTEwLTEwIDAtNS41MjI4IDQuNDc3Mi0xMCAxMC0xMCA1LjUyMyAwIDEwIDQuNDc3MiAxMCAxMHoiIGZpbGw9IiNjMDM5MmIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMTAyOS40KSIvPjxwYXRoIGQ9Im0yMiAxMmMwIDUuNTIzLTQuNDc3IDEwLTEwIDEwLTUuNTIyOCAwLTEwLTQuNDc3LTEwLTEwIDAtNS41MjI4IDQuNDc3Mi0xMCAxMC0xMCA1LjUyMyAwIDEwIDQuNDc3MiAxMCAxMHoiIGZpbGw9IiNlNzRjM2MiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMTAyOC40KSIvPjxwYXRoIGQ9Im03LjA1MDMgMTAzNy44IDMuNTM1NyAzLjYtMy41MzU3IDMuNSAxLjQxNDIgMS40IDMuNTM1NS0zLjUgMy41MzYgMy41IDEuNDE0LTEuNC0zLjUzNi0zLjUgMy41MzYtMy42LTEuNDE0LTEuNC0zLjUzNiAzLjUtMy41MzU1LTMuNS0xLjQxNDIgMS40eiIgZmlsbD0iI2MwMzkyYiIvPjxwYXRoIGQ9Im03LjA1MDMgMTAzNi44IDMuNTM1NyAzLjYtMy41MzU3IDMuNSAxLjQxNDIgMS40IDMuNTM1NS0zLjUgMy41MzYgMy41IDEuNDE0LTEuNC0zLjUzNi0zLjUgMy41MzYtMy42LTEuNDE0LTEuNC0zLjUzNiAzLjUtMy41MzU1LTMuNS0xLjQxNDIgMS40eiIgZmlsbD0iI2VjZjBmMSIvPjwvZz48L3N2Zz4="/>}
          {props.info && <embed className={classes.errorMark} src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgZmlsbD0ibm9uZSIgaGVpZ2h0PSIyNCIgc3Ryb2tlPSIjMDAwIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTAiLz48bGluZSB4MT0iMTIiIHgyPSIxMiIgeTE9IjE2IiB5Mj0iMTIiLz48bGluZSB4MT0iMTIiIHgyPSIxMiIgeTE9IjgiIHkyPSI4Ii8+PC9zdmc+"/>}
          <h3>{props.title}</h3>
          {props.success && <svg className={classes.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enable-background="new 0 0 64 64"><path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50  l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047"/></svg>}
        </div>
        <p className={classes.message}>{props.message}</p>
        {buttonRow}
      </div>
    </div>
  );
}

export function presentAlert (properties) {
  render(<Modal
    title={properties.title}
    message={properties.message}
    confirmDialog={properties.confirmDialog}
    confirmHandler={properties.confirmHandler}
    success={properties.success}
    error={properties.error}
    info={properties.info}
    />, document.getElementById("alert-view-container"));

}
