import React from "react";
import ReactGA from "react-ga4";
import Visual from "./Contern visual.png";
import commonClasses from "../ArticleStyle.css"
import classes from "./campus-contern.css"
import FatArrow from "../../../containers/UI/FatArrow.svg"

const News = (props) => {
  
  return (
    <>
      <div className={commonClasses.container}>

        <div className={commonClasses.innerContainer}>
          <h3 className={commonClasses.dateLabel}>Luxembourg, December 7th 2023</h3>
          <h1>Reference: Campus Contern</h1>
          
          <div className={classes.visualContainer}>
            <img src={Visual} className={commonClasses.visual} alt="BACnet visual"/>
          </div>

          <p className={commonClasses.contentParagraph}>How did we manage to transform 16.000 m² of office buildings into a smarter campus whilst keeping the tenants, managers and investors satisfied? By changing every existing system in the building? Or perhaps a huge renovation? Not even close! In this case study covering the Campus Contern project in Luxembourg, you'll discover why Allorado's gateway is the ideal solution for upgrading large buildings without breaking the bank or disrupting existing infrastructure.</p>
          <p className={commonClasses.contentSubtitle}>The challenge</p>
          <p className={commonClasses.contentParagraph}>
            We had a simple mission going into this project: <b>making the whole campus more energy efficient</b> in a smart, modern way. This, however, was easier said than done.</p>
          <p className={commonClasses.contentParagraph}>
            The biggest obstacle to overcome was the sheer amount of different systems on site, especially considering they all have their own protocols and they were nowhere near a place where a cable could go to.</p>
          <p className={commonClasses.contentParagraph}>
            For example, every tenant has their own technical room with an electrical cabinet. In this case, traditional cable solutions are both cost-prohibitive and impractical, considering the impact on the tenants.</p>
         
          <p className={commonClasses.contentSubtitle}>The solution</p>
          <p className={commonClasses.contentParagraph}>
            We quickly realized that traditional solutions wouldn't cut it in this scenario; we needed a flexible and modern approach. Our first priority was establishing wireless communication for every system, so we decided to set up a LoRa network. We strategically positioned several LoRa hotspots throughout the buildings, all connected through the central hub, Allorado gateway.</p>
          <p className={commonClasses.contentParagraph}>
          It efficiently handled encryption and communication tasks. LoRa emerged as the ideal wireless protocol for several reasons:</p>

          <div className={classes.blokjesContainer}>
            <div className={classes.blokjesRow}>
              <div className={classes.subcontainer}>
                <h1>Installation</h1>
                <p>Installed in minutes, without any cable pulling</p>
              </div>
              <div className={classes.subcontainer}>
                <h1>Compatibility</h1>
                <p>A wide device range, created by hundreds of manufacturers</p>
              </div>
              <div className={classes.subcontainer}>
                <h1>Battery life</h1>
                <p>LoRa sensors last years on a single battery</p>
              </div>
            </div>
            <div className={classes.blokjesRow}>
              <div className={classes.subcontainer}>
                <h1>Privacy</h1>
                <p>All data remains fully on-site, no provider involved</p>
              </div>
              <div className={classes.subcontainer}>
                <h1>Coverage</h1>
                <p>Seamless coverage thanks to hotspots across the buildings</p>
              </div>
              <div className={classes.subcontainer}>
                <h1>Data security</h1>
                <p>Network communication is fully encrypted</p>
              </div>
            </div>
          </div>

          <p className={commonClasses.contentSubtitle}>The implementation</p>
          <p className={commonClasses.contentParagraph}>
            As mentioned earlier, every tenant in the building has their own technical room, which means separate electrical cabinets with separate electricity meters, and no room for cables . In this scenario, a LoRa sensor that reads electricity meters via pulses proved to be a cost-effective, hassle-free solution, causing no disruption to the tenants. This straightforward setup not only accelerates billing to tenants but also eliminates the need for periodic visits to manually read meters.</p>
          <p className={commonClasses.contentParagraph}>
            The whole building was full of opportunities, from the charging stations of electric vehicles, staircases and elevators in the building to the solar panels on the roof. One significant feature was a six-floor-high duct in each building that housed HVAC and water supply systems. We installed calorimeters to precisely measure tenants’ heat and cold water consumption. These operate using the M-bus protocol.</p>
          <p className={commonClasses.contentParagraph}>
            After consolidating all the data on the gateway, it is centralized and accessible through various channels, both internally and externally. For local automations, data is available to access via BACnet, a functionality integrated into the gateway by Allorado. This flexibility gives users access to a multitude of platforms, eliminating the constraints associated with the underlying systems and their support.</p>

          <p className={commonClasses.contentParagraph}>For a deeper dive into the technical details, <a href="mailto:info@allorado.io?subject=Campus Contern reference case" className={commonClasses.inlineLink} target="_blank" rel="noreferrer">get in touch with us</a>.</p>

          <p className={commonClasses.contentSubtitle}>The benefits</p>
          <p className={commonClasses.contentParagraph}>
            The solution we provided offered a range of advantages for all parties involved. Let's delve into the benefits starting with <b>the office space tenants</b>:
            <ul>
              <li>Consumption data helped them to find ways to reduce it and be more eco-friendly</li>
              <li>Access to the data of electricity consumed by electric lighting, which is mandatory in Luxembourg</li>
              <li>Anomaly detection of electricity consumption, heating and cooling, enabling them to stay ahead of problems and avoid high consumption</li>
            </ul>
            <b>The managers</b>:
            <ul>
              <li>Automated tenant meter readings, all consumption is automatically collected in a dashboard and prepared for billing</li>
              <li>Anomaly detection for  consumption, heating and cooling </li>
              <li>Detailed consumption and production data, which allows better energy balancing and more renewable energy to be consumed directly </li>
            </ul>
            <b>The investors</b>:
            <ul>
              <li>A central data platform, which can be shared with all relevant parties</li>
              <li>Detection of inefficiency and advanced PV monitoring </li>
              <li>Reduced risks and insurance premiums</li>
              <li>More frequent billing to all parties, enabled by fast, accurate measurements across the site</li>
            </ul>
          </p>

          <p className={commonClasses.contentSubtitle}>The result</p>
          <p className={commonClasses.contentParagraph}>
            This project has <b>a significant impact on every stakeholder</b> at the office site: tenants enjoy improved comfort, managers streamline their operations for greater efficiency, and investors can maximize the building's return-on-investment through data-driven insights. On the technical front, there's a remarkable collaboration of various technologies, both new and old, all made possible by the core of the system: the Allorado Gateway.</p>


         <div className={commonClasses.callToAction}>
            <h3>Does your building need an upgrade?</h3>
            <h4>Unlock the power of data-driven building management.</h4>
            <div className={commonClasses.orderRow}>
              <button className={commonClasses.orderButton} onClick={() => {
                  ReactGA.event({ category: "Button", action: "News item to contact" })
                  window.location = "mailto:sales@allorado.io?subject=Upgrading my building"
                }}>Get in touch today</button>
              <img src={FatArrow} className={commonClasses.arrow} alt="arrow"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
