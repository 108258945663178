import React from "react";
import classes from "../Style.css"
import DocPage from "../DocPage/DocPage"
import { NavLink } from "react-router-dom";

import Figure1 from "../Figures/11-1.png"

const DocumentationPage = (props) => {

  return (
    <>
      <DocPage title={"Using the BACnet protocol"} link={"https://gateway.allorado.io/docs/11.%20Using%20BACnet.pdf"}>
        <div className={classes.docContainer}>

          <p>The Allorado Gateway makes it easier than ever to connect LoRa to BACnet and vice versa. Multiple ways of working with BACnet are offered, each suited for different applications:</p>
          <ol>
            <li>A “LoRa to BACnet” mapper is available on the web interface</li>
            <li>A “BACnet Objects” node is available in NodeRED</li>
          </ol>

          <h3>1. Web interface vs. NodeRED</h3>
          <p>The web interface offers an automatic mapper from LoRa to BACnet. For each provisioned LoRa device, one can choose which values to make available in BACnet. For example, one can choose to expose the temperature object of a sensor but not the humidity. Once selected, the mapping is made available and your work is done.</p>
          <p>The NodeRED node offers more freedom and more possibilities, at the cost of some manual work. The user is free to create flows and interact with BACnet objects from any other node or protocol. Connecting the BACnet node to a LoRa Downlink node makes it possible to switch a LoRa smart plug relay by changing a BACnet binary value, for example.</p>

          <h3>2. Using the web interface</h3>
          <ol>
            <li>Log into your Allorado Gateway</li>
            <li>Head over to the “Advanced” tab, under LoRa</li>
            <li>Activate “LoRa to BACnet”</li>
            <li>Head over to the newly created “LoRa to BACnet” tab</li>
            <li>Choose a device ID and network number</li>
            <div className={classes.listIntermezzo}><b>Tip</b>: The device ID is a unique number between 0 and 4.194.304. All BACnet objects you define have an ID higher than the device ID. Example: if you set the device ID to 2000, the mapper will assign the first object an ID of 2001, the second 2002 and so on.</div>

            <li>Click “Edit LoRa selection” and select which data points you want to expose to BACnet for each provisioned LoRa device.</li>
            <li>As you select data points, a table with the proposed BACnet mapping appears below. It shows the assigned name, the associated unit and the corresponding instance ID.</li>
            <li>When you are satisfied with the mapping, click the “Deploy” button to make the changes take effect.</li>
            <li>You will see the newly defined objects appear on the BACnet network. This can be easily verified by using a third-party BACnet explorer, like Yabe.</li>
            <li>When a selected LoRa device transmits a new data, head back over to the “LoRa to BACnet” page and you will see new value under “current value”. The new value also propagates on the BACnet network.</li>
          </ol>

          <h3>3. Using the NodeRED node</h3>
          <ol>
            <li>Open NodeRED from the NodeRED tab</li>
            <li>Scroll down in the left pane to the “BACnet device” category</li>
            <li>Drag a “BACnet Objects” node into your flow</li>
            <li>Double click the node to configure it</li>
            <li>Create a BACnet controller and enter a device ID and network number</li>
            <li>Click “Create” to return to the Objects editor</li>
          </ol>

          <p>The <b>Objects editor</b> is designed to let you define BACnet objects. It consists of two editors:</p>
          <li>A <b>table</b> editor: simple editor which best assists you in defining objects</li>
          <li>A <b>text</b> editor: more complex editor, allows bulk definition of objects for advanced users</li>
          <p>Object definitions are shared between these two editors. You can define an object in the table editor and see what its definition looks like in the text editor.</p>
          <p>Multiple object types are supported. The types “analog input”, “binary input” and “character string value” are <b>read-only</b>, their value can only be updated from NodeRED, not from the BACnet network. The types “analog value” and “binary value” can be updated both from NodeRED and the external BACnet network. Hence to control a LoRa device from BACnet, you need an analog or binary value.</p>
          <p>Each object has a unique instance ID. These IDs depend on the device ID, so the Object editor asks you to choose an offset ID. If the device ID is 2000 and you enter an offset ID of 50, the final instance ID visible on BACnet network is 2050. These offset IDs need to be unique across all “BACnet Objects” nodes.</p>

          <h4>Defining BACnet objects</h4>
          <p>As mentioned above, defining BACnet objects happens within the configuration panel of the “BACnet Objects” node.</p>

          <h4>Updating BACnet object values</h4>
          <p>To update the value of a BACnet object (and make this new value visible on the BACnet network), a message can be injected into the node on the left side. To update an object, we need:</p>
          <ul>
            <li>The instance ID (absolute) or offset ID (relative to device ID) of the object</li>
            <li>The new value</li>
          </ul>
          <p>The injected message needs to be an array of updates of the following format:</p>
          <img style={{ maxHeight: "120px" }} className={classes.structureImage} src={Figure1} alt="Input message format for the BACnet Objects node."/>
          <p>Either <i>absoluteID</i> or <i>offsetID</i> needs to be specified, up to the user to choose. If the message format is incorrect, an error will be displayed in the NodeRED debug console. When the update succeeds, the value change will be visible across the BACnet network.</p>
          <p>Please refer to our <NavLink className={classes.docLink} to={"/docs/example-flows"}>example flows</NavLink> for a complete example of updating BACnet object values.</p>
          
          <h4>Receiving BACnet updates</h4>
          <p>When a value is updated from the BACnet network, a message is sent out on the right of the node. This message contains the new value and the absoluteID which was updated. A message is only returned in case of an external update, not when injecting an update yourself on the left of the node.</p>
          <p><b>Note</b>: when BACnet objects are defined, they do not have a valid value yet. The object is marked as “Out-of-Service” to indicate this value hasn’t been set yet. When writing a value to the BACnet object for the first time, you will get an ACCES_DENIED error because of this. Before the first update, you will need to update the “Out-of-Service” flag to false and only then writing a new value is allowed.</p>
          <p>By connecting a LoRa downlink node on the right with a Function node in between for some logic, LoRa devices can be controlled and updated from the BACnet network. Please refer to our <NavLink className={classes.docLink} to={"/docs/example-flows"}>example flows</NavLink> for a complete example of controlling a LoRa smart plug from BACnet.</p>

          <h3>4. Useful tips</h3>
          <ul>
            <li>The web interface mapper and the NodeRED nodes are designed to work simultaneously. It is useful however to keep apart the NodeRED instance IDs and the mapper instance IDs. For example, if you create 24 BACnet objects via the mapper, start your NodeRED offset IDs at 50 instead of at 25 (the next available slot. Otherwise, if you want to map one more object automatically on the web interface, it has to find a free instance ID which might be far apart from the other automatic objects.</li>
            <li>Under 200 automatically mapped objects, the web interface instantly recalculates the mapping whenever you select or deselect a data point from a LoRa device. Over 200, this recalculation becomes too time-consuming to do at every single select / deselect. When mapping more than 200 objects automatically, the user has to manually click “recalculate” when all desired LoRa data points are selected.</li>
            <li>The web interface is currently tested up to multiple thousands of automatically mapped objects without hurting performance.</li>
            <li>The BACnet stack is currently (artificially) limited to 2.500 objects per object type (ai, bi, av, bv and csv) for a grand total of 12.500 objects.</li>

          </ul>
        </div>
      </DocPage>
    </>
  );
}

export default DocumentationPage;
