

// MARK: - Authentication types

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";


// MARK: - Node instalation types

export const INSTALL_UPDATE_STAGED = "INSTALL_STAGE_NEW";
export const INSTALL_START_INSTALL = "INSTALL_START_INSTALL";
