import React from "react";
import classes from "./CollapseRow.css"
import Collapsible from "react-collapsible";
import Arrow from "./arrow.svg"

const CollapseRow = (props) => {

  return (
    <Collapsible trigger={
      <div className={classes.rowHeader}>
        <div className={classes.rowTitle + (props.subtle ? (" " + classes.subtle) : "")}>{props.title}</div>
        {props.difficulty ? <div className={classes.difficultyLabel}>({props.difficulty})</div> : ""}
        {props.link ? <a href={props.link} target="_blank" rel="noreferrer" onClick={(event) => {
          event.stopPropagation()
        }}>Download flow</a> : ""}
        <img className={classes.arrow} src={Arrow} alt=""/>
      </div>}
      transitionTime={300}
      className={classes.collapsibleItemClosed + (props.firstRow ? (" " + classes.firstRow) : "") + (props.lastRow ? (" " + classes.lastRow) : "")}
      openedClassName={classes.collapsibleItemOpen + (props.firstRow ? (" " + classes.firstRow) : "") + (props.lastRow ? (" " + classes.lastRow) : "")}
      triggerClassName={classes.header + (props.subtle ? (" " + classes.subtle) : "")}
      triggerOpenedClassName={classes.header + (props.subtle ? (" " + classes.subtle) : "")}>
      <div className={classes.collapsibleContent}>
       {props.children}
      </div>
    </Collapsible>
  );
}

export default CollapseRow;
