import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import Layout from "./containers/Layout/Layout";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

// General
import Zero       from "./components/0-usecases/Gateway";
import Gateway    from "./components/1-gateway/Gateway";
import News       from "./components/2-news/News";
import Story      from "./components/3-story/Story";
import Docs       from "./components/4-resources/Resources";
import Software   from "./components/5-software/Software";
import Order      from "./components/6-order/Order";

// Documentation
import doc1 from "./components/4-resources/Documentation/1-specification-sheet/specification-sheet";
import doc2 from "./components/4-resources/Documentation/2-getting-started/getting-started";
import doc3 from "./components/4-resources/Documentation/3-status-leds/status-leds";
import doc4 from "./components/4-resources/Documentation/4-logging-in/logging-in";
import doc5 from "./components/4-resources/Documentation/5-networking/networking";
import doc6 from "./components/4-resources/Documentation/6-installing-license/installing-license";
import doc7 from "./components/4-resources/Documentation/7-updating-library/updating-library";
import doc8 from "./components/4-resources/Documentation/8-testing-lora/testing-lora";
import doc9 from "./components/4-resources/Documentation/9-lora-nodes/lora-nodes";
import doc10 from "./components/4-resources/Documentation/10-knx-nodes/knx-nodes";
import doc11 from "./components/4-resources/Documentation/11-using-bacnet/using-bacnet";
import doc12 from "./components/4-resources/Documentation/12-example-flows/example-flows";

// News
import news1 from "./components/2-news/1-first-distributor/first-distributor";
import news2 from "./components/2-news/2-introducing-bacnet/introducing-bacnet";
import news3 from "./components/2-news/3-campus-contern/campus-contern";
import news4 from "./components/2-news/4-smart-heating/smart-heating";
import news5 from "./components/2-news/5-knx-incubator/knx-incubator";
import news6 from "./components/2-news/6-siemens-bac/siemens-bac";
import news7 from "./components/2-news/7-light-building/light-building";
import news8 from "./components/2-news/8-knx-feature/knx-feature";
import news9 from "./components/2-news/9-iotswc/iotswc";
import news10 from "./components/2-news/10-futurasmus/futurasmus";
import news11 from "./components/2-news/11-knx-dialogue/knx-dialogue";

// LoRa to BACnet
import bacnet from "./components/11-lora-to-bacnet/bacnet";
import knx from "./components/12-lora-to-knx/knx";


import * as actions from "./store/actions/auth";

// Setup analytics
ReactGA.initialize("G-2MR8P81V3M")


class App extends Component {

  componentDidMount() {
    this.props.attemptSessionRestoration()
  }

  render() {

    // Redirect if necessary
    let redirect = null;
    // If authenticated
    if (this.props.history.location.pathname === "/") {
      redirect = (<Redirect to="/gateway"/>);
    }

    return (
      <div className="App">
        <div id="alert-view-container"></div>
        <Layout
            isAuthenticated={true}
            logout={this.props.logout}>
          <Switch>
            <Route path="/0" exact component={Zero}></Route>
            <Route path="/gateway" exact component={Gateway}></Route>
            <Route path="/story" component={Story}></Route>
            <Route path="/community" component={() => { window.open("https://community.allorado.io", "_blank"); window.open("/", "_self"); return null }}/>
            <Route path="/docs" component={Docs} exact></Route>

            <Route path="/docs/specification-sheet" component={doc1}></Route>
            <Route path="/docs/getting-started" component={doc2}></Route>
            <Route path="/docs/status-leds" component={doc3}></Route>
            <Route path="/docs/logging-in" component={doc4}></Route>
            <Route path="/docs/networking" component={doc5}></Route>
            <Route path="/docs/installing-license" component={doc6}></Route>
            <Route path="/docs/updating-library" component={doc7}></Route>
            <Route path="/docs/testing-lora" component={doc8}></Route>
            <Route path="/docs/lora-nodes" component={doc9}></Route>
            <Route path="/docs/knx-nodes" component={doc10}></Route>
            <Route path="/docs/using-bacnet" component={doc11}></Route>
            <Route path="/docs/example-flows" component={doc12}></Route>

            <Route path="/software" component={Software}></Route>
            <Route path="/order" component={Order}></Route>

            <Route path="/news" component={News} exact></Route>
            <Route path="/news/first-distributor" component={news1}></Route>
            <Route path="/news/introducing-bacnet" component={news2}></Route>
            <Route path="/news/campus-contern" component={news3}></Route>
            <Route path="/news/smart-heating" component={news4}></Route>
            <Route path="/news/knx-startup-incubator" component={news5}></Route>
            <Route path="/news/siemens-bac" component={news6}></Route>
            <Route path="/news/light-building-2024" component={news7}></Route>
            <Route path="/news/introducing-knx" component={news8}></Route>
            <Route path="/news/iotswc-2024" component={news9}></Route>
            <Route path="/news/futurasmus-distributor" component={news10}></Route>
            <Route path="/news/knx-dialogue-series" component={news11}></Route>

            <Route path="/lora-to-bacnet" component={bacnet} exact></Route>
            <Route path="/lora-to-knx" component={knx} exact></Route>
          </Switch>

          <Route path="/" render={() => {

            let page = window.location.pathname
            switch (page) {
              case "/gateway":    page = "Gateway"; break;
              case "/story":      page = "Our story"; break;
              case "/community":  page = "Community"; break;
              case "/docs":       page = "Docs"; break;
              case "/software":   page = "Software"; break;
              case "/order":      page = "Order"; break;
              case "/news":       page = "News"; break;
              default: break;
            }

            ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: page });
            return null;
          }} />
        </Layout>
        {redirect}
      </div>
    );
  }
}


// MARK: - Redux

const mapStateToProps = (state) => {
  return { isAuthenticated: state.auth.isAuthenticated }
}
const mapDispatchToProps = (dispatch) => {
  return {
    attemptSessionRestoration: () => dispatch(actions.attemptSessionRestoration()),
    logout: () => dispatch(actions.logout())
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
