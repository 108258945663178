import React from "react";
import ReactGA from "react-ga4";
import { NavLink } from "react-router-dom";
import commonClasses from "../ArticleStyle.css"
import classes from "./knx-feature.css"
import FatArrow from "../../../containers/UI/FatArrow.svg"

const News = (props) => {

  return (
    <>
      <div className={commonClasses.container}>

        <div className={commonClasses.innerContainer}>
          <h3 className={commonClasses.dateLabel}>Tervuren, April 12th 2024</h3>
          <h1>Introducing KNX on the Allorado Gateway</h1>
          
          <div className={classes.iframeContainer}><iframe className={classes.videoFrame} width="620" height="340" src={"https://www.youtube.com/embed/WzJaZYyH0bI"} allowFullScreen title="LoRa to KNX in one minute"></iframe></div>

          <p className={commonClasses.contentParagraph}>Today, Allorado unveils its latest innovation — the LoRa to KNX mapper. This groundbreaking feature seamlessly bridges LoRa devices with KNX installations, offering a new level of flexibility for KNX installers,  even in challenging environments where wiring isn't feasible.</p>
          <p className={commonClasses.contentParagraph}>To see the LoRa to KNX mapper in action and discover how easily it can transform your building automation projects, watch the one-minute video above.</p>
          
          <div className={commonClasses.callToAction}>
            <h3>Ready to transform your building?</h3>
            <div className={commonClasses.orderRow}>
              <NavLink className={commonClasses.orderButton} to={"/order"} onClick={() => ReactGA.event({ category: "Button", action: "News item to order" })}>Order your Allorado today</NavLink>
              <img src={FatArrow} className={commonClasses.arrow} alt="arrow"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
